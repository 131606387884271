import { memo } from 'react';
import type { FC } from 'react';

import resets from '../../../_resets.module.css';
import classes from './MenuComponent.module.css';
import { Line } from './Line';
import { useNavigate } from 'react-router-dom';

interface Props {
  className?: string;
  classes?: {
    root?: string;
  };
}
// appearing menu
export const MenuComponent: FC<Props> = memo(function Component14_Property1Default(props = {}) {

    const navigate = useNavigate();
    function goToHome(){
        navigate("/");
        window.scrollTo(0,0);
    }

    function goToContact(){
        navigate("/contact");
        window.scrollTo(0,0);
    }

    function goToAbout(){
        navigate("/about");
        window.scrollTo(0,0);
    }
  return (
    <div className={`${resets.clapyResets} ${props.classes?.root || ''} ${props.className || ''} ${classes.root}`}>
      <div className={classes.menuItem}>
      <div><Line/></div>
        <div onClick={goToHome}>Home</div>
      </div>
      <div className={classes.menuItem}>
      <div><Line/></div>
        <div onClick={goToAbout}>About</div>
      </div>
      <div className={classes.menuItem}>
        <div><Line/></div>
        <div onClick={goToContact}>Contact Us</div>
      </div>
    </div>
  );
});
