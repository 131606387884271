import { memo, useState } from 'react';
import type { FC, ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';

import resets from '../../../_resets.module.css';
import classes from './ContactButton_Property1Default.module.css';
import { MaterialSymbolsMailOutlineIcon } from './MaterialSymbolsMailOutlineIcon';

interface Props {
  className?: string;
  classes?: {
    root?: string;
  };
  text?: {
    contactUs?: ReactNode;
  };
}
/* @figmaId 1:5 */
export const ContactButton_Property1Default: FC<Props> = memo(function ContactButton_Property1Default(props = {}) {

  const [fill, setFill] = useState('#dcdcdc');

  const navigate = useNavigate();
  function goToContact() {
    navigate('/contact');
    window.scrollTo(0, 0);
  }

  return (
    <div className={`${resets.clapyResets} ${props.classes?.root || ''} ${props.className || ''} ${classes.root} ${resets.clickable}`}
    onMouseEnter={() => setFill('#24FFB5')} 
      onMouseLeave={() => setFill('#dcdcdc')}
      onClick={goToContact}
    >
      <div className={classes.materialSymbolsMailOutline}>
        <MaterialSymbolsMailOutlineIcon className={classes.icon} fill={fill}/>
      </div>
      {props.text?.contactUs != null ? props.text?.contactUs : <div className={classes.contactUs}>Contact Us</div>}
    </div>
  );
});
