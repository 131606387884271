import { memo, SVGProps, useState } from 'react';
import { Component3 } from './Component3/Component3';
import classes from './Footer.module.css';
import { VectorIcon } from './VectorIcon';

import HoveredFacebookLogo from '../../../../res/Desktop/facebook-logo-green.png';
import FacebookLogo from '../../../../res/Desktop/facebook-logo.png';
import HoveredLinkedinImage from '../../../../res/Desktop/linkedin-logo-green.png';
import LinkedInImage from '../../../../res/Desktop/linkedin-logo.png';
import HoveredTwitterLogo from '../../../../res/Desktop/twitter-logo-green.png';
import TwitterLogo from '../../../../res/Desktop/twitter-logo.png';
import HoveredYoutubeLogo from '../../../../res/Desktop/youtube-logo-green.png';
import YoutubeLogo from '../../../../res/Desktop/youtube-logo.png';

interface FooterProps {
    // classes: {
    //   [key: string]: string;
    // };
  }
  
  const Footer: React.FC<FooterProps> = ({ 
    // classes 
  }) => {

    const [isHoveredLinkedin, setIsHoveredLinkedin] = useState(false);
  const [isHoveredFacebook, setIsHoveredFacebook] = useState(false);
  const [isHoveredTwitter, setIsHoveredTwitter] = useState(false);
  const [isHoveredYoutube, setIsHoveredYoutube] = useState(false);


  const handleMouseEnterLinkedin = () => {
    setIsHoveredLinkedin(true);
  };

  const handleMouseLeaveLinkedin = () => {
    setIsHoveredLinkedin(false); 
  };

  const handleMouseEnterFacebook = () => {
    setIsHoveredFacebook(true);
  };
  
  const handleMouseLeaveFacebook = () => {
    setIsHoveredFacebook(false);
  };
  
  const handleMouseEnterTwitter = () => {
    setIsHoveredTwitter(true);
  };
  
  const handleMouseLeaveTwitter = () => {
    setIsHoveredTwitter(false);
  };
  
  const handleMouseEnterYoutube = () => {
    setIsHoveredYoutube(true);
  };
  
  const handleMouseLeaveYoutube = () => {
    setIsHoveredYoutube(false);
  };

  return (
<div className={classes.pageEnd}>
        <div className={classes.computerTech}>Computer Tech.</div>
        <div className={classes.ankaraTEKMERCevizlidereMahMevl}>
          <p className={classes.labelWrapper}>
            <span className={classes.label}>Ankara TEKMER</span>
            <span className={classes.label2}> </span>
          </p>
          <div className={classes.textBlock}>Cevizlidere Mah. Mevlana Bul. No:221</div>
          <div className={classes.textBlock2}>İç Kapı No: 105</div>
          <div className={classes.textBlock3}>
            <p className={classes.labelWrapper2}>
              <span className={classes.label3}>ÇANKAYA/ANKARA</span>
            </p>
          </div>
        </div>
        <Component3 />
        {/* small icons on footer */}
      {/* linkedin */}
      <a href='https://www.linkedin.com/company/ronobt/about/'>
      <div className={classes.frame7}>
      <img
      src={isHoveredLinkedin ? HoveredLinkedinImage : LinkedInImage}
      className={classes.linkedin}
      onMouseEnter={handleMouseEnterLinkedin}
      onMouseLeave={handleMouseLeaveLinkedin}
      alt="Linkedin Logo"
      />
      </div>
      </a>
      {/* facebook */}
      <div className={classes.frame8}>
      <img
      src={isHoveredFacebook ? HoveredFacebookLogo : FacebookLogo}
      className={classes.facebook}
      onMouseEnter={handleMouseEnterFacebook}
      onMouseLeave={handleMouseLeaveFacebook}
      alt="Facebook Logo"
      />
      </div>
      {/* twitter */}
      <div className={classes.frame9}>
      <img
      src={isHoveredTwitter ? HoveredTwitterLogo : TwitterLogo}
      className={classes.twitter}
      onMouseEnter={handleMouseEnterTwitter}
      onMouseLeave={handleMouseLeaveTwitter}
      alt="Twitter Logo"
      />
      </div>
      {/* youtube */}
      <div className={classes.frame10}>
      <img
      src={isHoveredYoutube ? HoveredYoutubeLogo : YoutubeLogo}
      className={classes.youtube}
      onMouseEnter={handleMouseEnterYoutube}
      onMouseLeave={handleMouseLeaveYoutube}
      alt="YouTube Logo"
      />
      </div>
        <div className={classes.vector}>
          <VectorIcon className={classes.icon6} />
        </div>
      </div>


  
);
  };
const Memo = memo(Footer);
export { Memo as Footer };
