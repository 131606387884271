import { memo, SVGProps } from 'react';

const WhiteRectangleIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg preserveAspectRatio='none' viewBox='0 0 1440 4738' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M0 89.9999C0 40.2943 40.2944 0 90 0H1350C1399.71 0 1440 40.2944 1440 90V4648C1440 4697.71 1399.71 4738 1350 4738H90C40.2944 4738 0 4697.71 0 4648V89.9999Z'
      fill='#DCDCDC'
    />
  </svg>
);

const Memo = memo(WhiteRectangleIcon);
export { Memo as WhiteRectangleIcon };
