import { memo, SVGProps } from 'react';

const Line = (props: SVGProps<SVGSVGElement>) => (

<svg width="1" height="26" viewBox="0 0 1 26" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M0.5 25.5L0.5 0.5" stroke="#24FFB5"/>
</svg>
);

const Memo = memo(Line);
export { Memo as Line };
