import { memo } from 'react';
import type { FC } from 'react';

import resets from '../../../_resets.module.css';
import classes from './MenuLines.module.css';
import { VectorIcon } from './VectorIcon';

interface Props {
  className?: string;
  classes?: {
    root?: string;
  };
}
// 3 lines at the right side of header
// export const Component14_Property1Default: FC<Props> = memo(function Component14_Property1Default(props = {}) {
  export const MenuLines: FC<Props & { onClick: () => void }> = memo(function Component14_Property1Default(props) {
  return (
    <div className={`${resets.clapyResets} ${props.classes?.root || ''} ${props.className || ''} ${classes.root}`}
    onClick={props.onClick}>
      <div className={classes.vector2}>
        <VectorIcon className={classes.icon} />
      </div>
      <div className={classes.vector3}>
        <VectorIcon className={classes.icon2} />
      </div>
      <div className={classes.vector4}>
        <VectorIcon className={classes.icon3} />
      </div>
    {/* <MenuComponent/> */}
    </div>
  );
});
