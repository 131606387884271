import { memo, SVGProps } from 'react';

const MainpageDataIllustrationIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg preserveAspectRatio='none' viewBox='0 0 335 345' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M279.168 128.156L223.337 148.545L167.505 128.156L223.337 107.773L279.168 128.156ZM223.337 20.383L167.505 40.7713L111.674 20.383L167.505 0L223.337 20.383ZM167.505 82.0029L111.674 102.391L55.8473 82.0081H55.8421V82.0029L111.674 61.6198L167.505 82.0029Z'
      fill='#DCDCDC'
    />
    <path d='M55.8578 304.218V304.229L55.8473 304.223L55.8578 304.218Z' fill='#1F1F1F' />
    <path
      d='M223.337 183.207L167.5 203.59L111.684 183.212L111.668 183.207L111.684 183.201L167.5 162.818H167.505L223.337 183.207ZM111.668 152.033L55.8368 172.422L0.00524051 152.033L55.8368 131.65H55.8421L111.668 152.033Z'
      fill='#DCDCDC'
    />
    <path d='M55.8368 304.223V304.229L55.8316 304.223H55.8368Z' fill='#1F1F1F' />
    <path
      d='M55.8368 172.422V304.229L55.8316 304.223H55.8263L0.00524051 283.84V152.033L55.8368 172.422ZM167.5 203.59V345L111.674 324.612H111.668L111.663 324.607V283.846L111.674 283.84L111.663 283.835L111.668 183.207L111.684 183.212L167.5 203.59Z'
      fill='#24FFB5'
    />
    <path
      d='M335 283.835L167.5 345V203.59L223.337 183.207V148.545L279.168 128.156V263.452H279.174L335 283.835Z'
      fill='#1F1F1F'
    />
    <path
      d='M111.674 102.391L111.668 152.033L55.8421 131.65H55.8368L0.0157295 111.267V61.6198L55.8421 82.0029V82.0081H55.8473L111.674 102.391ZM167.505 40.7713V82.0029L111.674 61.6198V20.383L167.505 40.7713ZM223.337 148.545V183.207L167.505 162.818V128.156L223.337 148.545Z'
      fill='#24FFB5'
    />
    <path
      d='M279.168 0V87.3902L223.337 107.773L167.505 128.156V162.818H167.5L111.684 183.201L111.668 183.207L111.663 283.835L111.674 283.84L111.663 283.846V324.607L55.8316 344.995L0 324.607L55.8263 304.223H55.8316L55.8368 304.229V304.223H55.8473L55.8578 304.229V304.234H55.863V304.229H55.8578V172.427L111.668 152.044V152.033L111.674 102.391L167.505 82.0029V40.7713L223.337 20.383V20.3883L279.168 0Z'
      fill='#1F1F1F'
    />
    <path
      d='M55.8578 304.229H55.863V304.234H55.8578V304.229ZM111.695 283.846L111.684 283.851L111.663 283.856V283.846L111.674 283.84L111.663 283.835L111.668 183.207L111.684 183.201V283.84L111.695 283.846Z'
      fill='#1F1F1F'
    />
  </svg>
);

const Memo = memo(MainpageDataIllustrationIcon);
export { Memo as MainpageDataIllustrationIcon };
