import { memo, SVGProps } from 'react';

const VectorIcon3 = (props: SVGProps<SVGSVGElement>) => (
  <svg preserveAspectRatio='none' viewBox='0 0 18 28' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M9.29141 0.0026024C8.37122 -0.0188361 7.42057 0.0919786 6.44705 0.34262C1.9451 1.50127 -3.09429 7.4681 2.40837 14.3481L5.69274 18.4445L6.61991 19.6103C7.24221 20.3726 8.26053 20.359 9.00855 19.7236C9.76034 19.0851 9.97406 18.0378 9.35427 17.2626C9.35348 17.2615 9.09145 16.9347 8.97712 16.793H8.99283C8.75544 16.4988 8.70392 16.4494 8.45853 16.1454C7.26987 14.6724 5.91275 12.9719 5.91275 12.9719C3.52349 9.98424 4.58957 6.29326 7.82994 4.19616C10.685 2.3484 15.9243 3.00836 17.7302 6.04197C16.5928 2.58314 13.2789 0.0955025 9.29141 0.0026024ZM9.95143 7.22394C9.52689 7.20904 9.08366 7.37581 8.70997 7.69349C7.95692 8.33272 7.74508 9.38127 8.36424 10.1546C8.3655 10.1565 8.4403 10.2524 8.56853 10.4136C9.99285 12.1768 11.8843 14.5424 11.8843 14.5424C14.2742 17.53 13.2075 21.2048 9.96714 23.3019C7.11272 25.149 1.87282 24.4878 0.0668829 21.4561C1.46675 25.7112 6.15853 28.4929 11.35 27.1555C15.8532 25.9949 20.8914 20.0449 15.3887 13.1662L11.5386 8.37352L11.0986 7.80683C10.7874 7.42568 10.376 7.23883 9.95143 7.22394Z'
      fill='#353535'
    />
  </svg>
);

const Memo = memo(VectorIcon3);
export { Memo as VectorIcon3 };
