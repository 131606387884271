import type { FC } from 'react';
import { memo } from 'react';

import resets from '../../_resets.module.css';
import { Footer } from '../Shared/Footer/Footer';
import { ContactButton_Property1Default } from './ContactButton_Property1Default/ContactButton_Property1Default';
import classes from './Desktop1440ContactPage.module.css';
import { Frame18_Property1Default } from './Frame18_Property1Default/Frame18_Property1Default';
import { Logo } from './Logo/Logo';
import { OkIcon } from './OkIcon';
import { SendEmailForm } from './SendEmailForm/SendEmailForm';

interface Props {
  className?: string;
}
/* @figmaId 135:94 */
export const Desktop1440ContactPage: FC<Props> = memo(function Desktop1440ContactPage(props = {}) {
  return (
    <div className={`${resets.clapyResets} ${classes.root}`}>
      <div className={classes.contactUsText}>Contact Us.</div>
      <div className={classes.ok}>
        <OkIcon className={classes.icon} />
      </div>
      <SendEmailForm className={classes.sendEmailForm}/>
      <Footer/>
      <div className={classes.hEADER}>
        <ContactButton_Property1Default
          className={classes.contactButton}
          text={{
            contactUs: <div className={classes.contactUs}>Contact Us</div>,
          }}
        />
        <Logo />
        <Frame18_Property1Default className={classes.frame18} />
      </div>
    </div>
  );
});
