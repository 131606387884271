import React, { useState } from 'react';

import { Component4 } from './Component4/Component4';

import HoveredFacebookLogo from '../../../../res/Desktop/facebook-logo-green.png';
import FacebookLogo from '../../../../res/Desktop/facebook-logo.png';
import HoveredLinkedinImage from '../../../../res/Desktop/linkedin-logo-green.png';
import LinkedInImage from '../../../../res/Desktop/linkedin-logo.png';
import HoveredTwitterLogo from '../../../../res/Desktop/twitter-logo-green.png';
import TwitterLogo from '../../../../res/Desktop/twitter-logo.png';
import HoveredYoutubeLogo from '../../../../res/Desktop/youtube-logo-green.png';
import YoutubeLogo from '../../../../res/Desktop/youtube-logo.png';
import classes from './Footer.module.css';
import { VectorIcon9 } from './VectorIcon9';
interface FooterProps {
  // classes: {
  //   [key: string]: string;
  // };
}

const Footer: React.FC<FooterProps> = ({ 
  // classes 
}) => {

  const [isHoveredLinkedin, setIsHoveredLinkedin] = useState(false);
  const [isHoveredFacebook, setIsHoveredFacebook] = useState(false);
  const [isHoveredTwitter, setIsHoveredTwitter] = useState(false);
  const [isHoveredYoutube, setIsHoveredYoutube] = useState(false);


  const handleMouseEnterLinkedin = () => {
    setIsHoveredLinkedin(true);
  };

  const handleMouseLeaveLinkedin = () => {
    setIsHoveredLinkedin(false); 
  };

  const handleMouseEnterFacebook = () => {
    setIsHoveredFacebook(true);
  };
  
  const handleMouseLeaveFacebook = () => {
    setIsHoveredFacebook(false);
  };
  
  const handleMouseEnterTwitter = () => {
    setIsHoveredTwitter(true);
  };
  
  const handleMouseLeaveTwitter = () => {
    setIsHoveredTwitter(false);
  };
  
  const handleMouseEnterYoutube = () => {
    setIsHoveredYoutube(true);
  };
  
  const handleMouseLeaveYoutube = () => {
    setIsHoveredYoutube(false);
  };

  return (
    <div className={classes.end}>
      <div className={classes.ankaraTEKMERCevizlidereMahMevl}>
        <div className={classes.textBlock8}>Ankara TEKMER </div>
        <div className={classes.textBlock9}>
          <p className={classes.labelWrapper}>
            <span className={classes.label}>Cevizlidere Mah. Mevlana Bul. No:221</span>
          </p>
        </div>
        <div className={classes.textBlock10}>İç Kapı No: 105</div>
        <div className={classes.textBlock11}>
          <p className={classes.labelWrapper2}>
            <span className={classes.label2}>ÇANKAYA/ANKARA</span>
          </p>
        </div>
      </div>
      <div className={classes.computerTech}>Computer Tech.</div>
      <div className={classes.vector8}>
        <VectorIcon9 className={classes.icon14} />
      </div>

      {/* small icons on footer */}
      {/* linkedin */}
      <a href='https://www.linkedin.com/company/ronobt/about/'>
      <div className={classes.frame7}>
      <img
      src={isHoveredLinkedin ? HoveredLinkedinImage : LinkedInImage}
      className={classes.linkedin}
      onMouseEnter={handleMouseEnterLinkedin}
      onMouseLeave={handleMouseLeaveLinkedin}
      alt="Linkedin Logo"
      />
      </div>
      </a>
      {/* facebook */}
      <div className={classes.frame14}>
      <img
      src={isHoveredFacebook ? HoveredFacebookLogo : FacebookLogo}
      className={classes.facebook}
      onMouseEnter={handleMouseEnterFacebook}
      onMouseLeave={handleMouseLeaveFacebook}
      alt="Facebook Logo"
      />
      </div>
      {/* twitter */}
      <div className={classes.frame9}>
      <img
      src={isHoveredTwitter ? HoveredTwitterLogo : TwitterLogo}
      className={classes.twitter}
      onMouseEnter={handleMouseEnterTwitter}
      onMouseLeave={handleMouseLeaveTwitter}
      alt="Twitter Logo"
      />
      </div>
      {/* youtube */}
      <div className={classes.frame10}>
      <img
      src={isHoveredYoutube ? HoveredYoutubeLogo : YoutubeLogo}
      className={classes.youtube}
      onMouseEnter={handleMouseEnterYoutube}
      onMouseLeave={handleMouseLeaveYoutube}
      alt="YouTube Logo"
      />
      </div>
      <Component4 className={classes.component4} />
    </div>
  );
};

export default Footer;
