import { memo, SVGProps } from 'react';

const VectorIcon2 = (props: SVGProps<SVGSVGElement>) => (
  <svg preserveAspectRatio='none' viewBox='0 0 41 26' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M5.53111 24.9824C4.95257 24.9882 4.46531 24.796 4.06933 24.4057C3.67336 24.0154 3.47316 23.5301 3.46874 22.9498C3.46294 22.3681 3.6541 21.8782 4.04222 21.4801C4.43033 21.082 4.91299 20.8808 5.49018 20.8764L16.7207 20.7644L0.634186 4.90949C0.256085 4.53683 0.0553754 4.06864 0.0320586 3.50492C0.00874169 2.94119 0.199902 2.4513 0.605539 2.03524C0.976137 1.65512 1.45071 1.46217 2.02925 1.45641C2.60779 1.45064 3.08611 1.63409 3.46421 2.00675L19.7564 18.0136L30.268 7.23202C30.1288 6.92542 30.0235 6.61027 29.9522 6.28657C29.8809 5.96286 29.8434 5.61214 29.8396 5.23438C29.8253 3.79725 30.3067 2.57762 31.2837 1.57548C32.2607 0.573343 33.4639 0.0651508 34.8932 0.0509048C36.3226 0.0366588 37.5356 0.520768 38.5325 1.50323C39.5293 2.4857 40.0348 3.69549 40.0492 5.13262C40.0635 6.56975 39.5821 7.78938 38.6051 8.79151C37.6281 9.79365 36.4249 10.3018 34.9956 10.3161C34.6893 10.3191 34.3909 10.2968 34.1004 10.249C33.81 10.2013 33.5288 10.1268 33.2569 10.0254L22.8464 20.7034L34.0769 20.5915C34.6555 20.5857 35.1427 20.7779 35.5387 21.1682C35.9347 21.5585 36.1349 22.0438 36.1393 22.6241C36.1451 23.2058 35.9539 23.6957 35.5658 24.0938C35.1777 24.4919 34.695 24.6931 34.1178 24.6975L5.53111 24.9824Z'
      fill='#353535'
    />
  </svg>
);

const Memo = memo(VectorIcon2);
export { Memo as VectorIcon2 };
