import { memo, SVGProps } from 'react';

const MainpageDataIllustrationIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg preserveAspectRatio='none' viewBox='0 0 205 186' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M170.834 69.093L136.669 80.0849L102.503 69.093L136.669 58.1039L170.834 69.093ZM136.669 10.9891L102.503 21.9811L68.3376 10.9891L102.503 0L136.669 10.9891ZM102.503 44.2103L68.3376 55.2022L34.1752 44.2131H34.172V44.2103L68.3376 33.2211L102.503 44.2103Z'
      fill='#DCDCDC'
    />
    <path d='M34.1816 164.013V164.019L34.1752 164.016L34.1816 164.013Z' fill='#1F1F1F' />
    <path
      d='M136.669 98.7723L102.5 109.761L68.344 98.7751L68.3344 98.7723L68.344 98.7695L102.5 87.7804H102.503L136.669 98.7723ZM68.3344 81.9658L34.1688 92.9577L0.00320688 81.9658L34.1688 70.9767H34.172L68.3344 81.9658Z'
      fill='#DCDCDC'
    />
    <path d='M34.1688 164.016V164.019L34.1656 164.016H34.1688Z' fill='#1F1F1F' />
    <path
      d='M34.1688 92.9577V164.019L34.1656 164.016H34.1624L0.00320688 153.027V81.9658L34.1688 92.9577ZM102.5 109.761V186L68.3376 175.008H68.3344L68.3312 175.005V153.03L68.3376 153.027L68.3312 153.024L68.3344 98.7723L68.344 98.7751L102.5 109.761Z'
      fill='#24FFB5'
    />
    <path
      d='M205 153.024L102.5 186V109.761L136.669 98.7723V80.0849L170.834 69.093V142.035H170.838L205 153.024Z'
      fill='#1F1F1F'
    />
    <path
      d='M68.3376 55.2022L68.3344 81.9658L34.172 70.9767H34.1688L0.00962553 59.9875V33.2211L34.172 44.2103V44.2131H34.1752L68.3376 55.2022ZM102.503 21.9811V44.2103L68.3376 33.2211V10.9891L102.503 21.9811ZM136.669 80.0849V98.7723L102.503 87.7804V69.093L136.669 80.0849Z'
      fill='#24FFB5'
    />
    <path
      d='M170.834 0V47.1147L136.669 58.1039L102.503 69.093V87.7804H102.5L68.344 98.7695L68.3344 98.7723L68.3312 153.024L68.3376 153.027L68.3312 153.03V175.005L34.1656 185.997L0 175.005L34.1624 164.016H34.1656L34.1688 164.019V164.016H34.1752L34.1816 164.019V164.022H34.1848V164.019H34.1816V92.9605L68.3344 81.9714V81.9658L68.3376 55.2022L102.503 44.2103V21.9811L136.669 10.9891V10.9919L170.834 0Z'
      fill='#1F1F1F'
    />
    <path
      d='M34.1816 164.019H34.1848V164.022H34.1816V164.019ZM68.3504 153.03L68.344 153.033L68.3312 153.035V153.03L68.3376 153.027L68.3312 153.024L68.3344 98.7723L68.344 98.7695V153.027L68.3504 153.03Z'
      fill='#1F1F1F'
    />
  </svg>
);

const Memo = memo(MainpageDataIllustrationIcon);
export { Memo as MainpageDataIllustrationIcon };
