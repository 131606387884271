import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';

import { App } from './App';
import './resets.css';
import '../src/components/Desktop/Shared/fullBody.module.css';

createRoot(document.getElementById('root')!).render(
  <StrictMode>
    <App />
  </StrictMode>,
);
