import { memo, SVGProps } from 'react';

const StarIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg preserveAspectRatio='none' viewBox='0 0 150 150' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M75 0L91.417 29.8948L123.209 17.5467L116.569 51L148.861 61.9764L122.271 83.3351L139.952 112.5L105.854 111.77L100.652 145.477L75 123L49.3485 145.477L44.1462 111.77L10.0481 112.5L27.7292 83.3351L1.13942 61.9764L33.4308 51L26.7909 17.5467L58.583 29.8948L75 0Z'
      fill='#24FFB5'
    />
  </svg>
);

const Memo = memo(StarIcon);
export { Memo as StarIcon };
