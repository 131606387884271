import { memo, SVGProps } from 'react';

const Star1Icon = (props: SVGProps<SVGSVGElement>) => (
  <svg preserveAspectRatio='none' viewBox='0 0 450 450' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M225 0L274.251 89.6843L369.627 52.64L349.708 153L446.582 185.929L366.812 250.005L419.856 337.5L317.561 335.31L301.955 436.431L225 369L148.045 436.431L132.439 335.31L30.1443 337.5L83.1877 250.005L3.41826 185.929L100.292 153L80.3728 52.64L175.749 89.6843L225 0Z'
      fill='#24FFB5'
    />
  </svg>
);

const Memo = memo(Star1Icon);
export { Memo as Star1Icon };
