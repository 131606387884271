import { memo, useState, useEffect } from 'react';
import type { FC } from 'react';

import resets from '../../../_resets.module.css';
import classes from './Subjects_Property1Frame17.module.css';

interface Props {
  className?: string;
  classes?: {
    root?: string;
  };
}

const subjects = ['Data Analytics', 'AI', 'Image Processing', 'Text Mining'];

export const Subjects_Property1Frame17: FC<Props> = memo(function Subjects_Property1Frame17(props = {}) {
  const [currentSubjectIndex, setCurrentSubjectIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentSubjectIndex((prevIndex) => (prevIndex + 1) % subjects.length);
    }, 1500);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <div className={`${resets.clapyResets} ${props.classes?.root || ''} ${props.className || ''} ${classes.root}`}>
      <div className={classes.dataAnalytics}>{subjects[currentSubjectIndex]}</div>
    </div>
  );
});
