import { memo, SVGProps } from 'react';

const VectorIcon8 = (props: SVGProps<SVGSVGElement>) => (
  <svg preserveAspectRatio='none' viewBox='0 0 71 39' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M64.1601 0.0581572C63.2104 0.0678668 62.2775 0.309316 61.4424 0.761528C60.6072 1.21374 59.8952 1.86304 59.368 2.65301C58.8408 3.44298 58.5145 4.34973 58.4174 5.29449C58.3202 6.23924 58.4553 7.19343 58.8107 8.07414L46.6597 20.4703C45.2862 19.9453 43.7647 19.9605 42.4019 20.5127L33.5407 11.8261C33.8793 10.9382 33.9959 9.98114 33.8803 9.03798C33.7648 8.09482 33.4206 7.19418 32.8777 6.41436C32.3348 5.63454 31.6096 4.99916 30.7652 4.56348C29.9208 4.1278 28.9827 3.90501 28.0326 3.91448C27.0825 3.92395 26.149 4.16539 25.3135 4.61782C24.4779 5.07024 23.7655 5.71995 23.2383 6.51043C22.711 7.30092 22.3848 8.20825 22.2881 9.15352C22.1914 10.0988 22.327 11.0534 22.6832 11.9343L8.22246 26.6868C6.97016 26.2099 5.59184 26.1787 4.31926 26.5986C3.04669 27.0184 1.95746 27.8636 1.23473 28.9921C0.512007 30.1205 0.199857 31.4635 0.35077 32.795C0.501683 34.1266 1.10646 35.3656 2.0634 36.3037C3.02034 37.2418 4.27109 37.8217 5.60534 37.9461C6.93959 38.0704 8.27597 37.7316 9.38977 36.9865C10.5036 36.2414 11.3269 35.1355 11.7212 33.8547C12.1156 32.574 12.0569 31.1965 11.5552 29.9539L26.0159 15.2013C27.3895 15.7263 28.911 15.7111 30.2738 15.1589L39.135 23.8456C38.7964 24.7334 38.6798 25.6905 38.7953 26.6337C38.9109 27.5768 39.2551 28.4775 39.798 29.2573C40.3409 30.0371 41.0661 30.6725 41.9105 31.1082C42.7549 31.5438 43.693 31.7666 44.6431 31.7572C45.5932 31.7477 46.5266 31.5062 47.3622 31.0538C48.1977 30.6014 48.9101 29.9517 49.4374 29.1612C49.9647 28.3707 50.2908 27.4634 50.3876 26.5181C50.4843 25.5728 50.3486 24.6182 49.9924 23.7373L62.1434 11.3412C62.9452 11.647 63.8046 11.7721 64.6603 11.7077C65.516 11.6432 66.3469 11.3907 67.0938 10.9682C67.8407 10.5457 68.4852 9.96358 68.9813 9.26338C69.4774 8.56317 69.813 7.76213 69.9639 6.91736C70.1149 6.07259 70.0777 5.20491 69.8548 4.37619C69.632 3.54747 69.229 2.77814 68.6747 2.12304C68.1204 1.46794 67.4284 0.943222 66.648 0.586299C65.8677 0.229376 65.0181 0.0490422 64.1601 0.0581572Z'
      fill='#353535'
    />
  </svg>
);

const Memo = memo(VectorIcon8);
export { Memo as VectorIcon8 };
