import { memo, SVGProps } from 'react';

const VectorIcon2 = (props: SVGProps<SVGSVGElement>) => (
  <svg preserveAspectRatio='none' viewBox='0 0 70 70' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M11.6667 54.4444H58.3333L43.75 35L32.0833 50.5556L23.3333 38.8889L11.6667 54.4444ZM11.6667 35C18.1482 35 23.6574 32.7315 28.1944 28.1944C32.7315 23.6574 35 18.1482 35 11.6667H28.3889C28.3889 16.3333 26.7685 20.287 23.5278 23.5278C20.287 26.7685 16.3333 28.3889 11.6667 28.3889V35ZM11.6667 21.7778C14.4537 21.7778 16.8039 20.7887 18.7172 18.8106C20.6306 16.8324 21.5859 14.4511 21.5833 11.6667H11.6667V21.7778ZM7.77778 70C5.63889 70 3.80723 69.2378 2.28278 67.7133C0.758339 66.1889 -0.00258599 64.3585 6.60253e-06 62.2222V7.77778C6.60253e-06 5.63889 0.762229 3.80723 2.28667 2.28278C3.81112 0.758339 5.64149 -0.00258599 7.77778 6.60253e-06H62.2222C64.3611 6.60253e-06 66.1928 0.762229 67.7172 2.28667C69.2417 3.81112 70.0026 5.64149 70 7.77778V62.2222C70 64.3611 69.2378 66.1928 67.7133 67.7172C66.1889 69.2417 64.3585 70.0026 62.2222 70H7.77778ZM7.77778 62.2222H62.2222V7.77778H7.77778V62.2222Z'
      fill='#353535'
    />
  </svg>
);

const Memo = memo(VectorIcon2);
export { Memo as VectorIcon2 };
