import { memo } from 'react';
import type { FC } from 'react';
import { useNavigate } from 'react-router-dom';

import resets from '../../../_resets.module.css';
import classes from './Frame18_Property1Default.module.css';

interface Props {
  className?: string;
  classes?: {
    root?: string;
  };
}
/* @figmaId 354:215 */
export const Frame18_Property1Default: FC<Props> = memo(function Frame18_Property1Default(props = {}) {

  const navigate = useNavigate();
  function goToAbout() {
    navigate('/about');
    window.scrollTo(0, 0);
  }

  return (
    <div className={`${resets.clapyResets} ${props.classes?.root || ''} ${props.className || ''} ${classes.root} ${resets.clickable}`} onClick={goToAbout}>
      <div className={classes.about}>About</div>
    </div>
  );
});
