import { memo, SVGProps } from 'react';

const OkIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg preserveAspectRatio='none' viewBox='0 0 160 116' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M6.55105 0.0636381C8.44134 0.427808 9.67824 2.25406 9.31375 4.14268C4.06821 31.3226 8.17867 48.9343 16.1505 59.7794C20.4036 65.5656 25.8999 69.6209 32.0277 72.2098C32.6455 60.3128 38.6168 49.2406 46.5881 41.4774C53.5623 34.6852 62.4122 30.0274 71.1918 29.5236C80.2122 29.006 88.7991 32.92 94.5313 42.4918C97.7829 47.9215 98.33 53.5123 96.6253 58.7288C94.9607 63.8229 91.2576 68.2502 86.5542 71.8143C77.1685 78.9261 62.9539 83.2642 48.8328 82.7735C45.8576 82.6701 42.8655 82.3509 39.9063 81.7913C41.0093 86.2223 43.1254 90.6424 46.4609 94.883C57.612 109.06 75.9198 111.832 96.2658 106.571C113.975 101.992 132.529 91.4409 147.471 78.1786L124.931 78.3505C123.006 78.3652 121.433 76.8179 121.419 74.8946C121.404 72.9712 122.953 71.4001 124.878 71.3855L156.248 71.1462C158.173 71.1315 159.746 72.6788 159.76 74.6021L160 105.945C160.015 107.868 158.466 109.439 156.541 109.454C154.616 109.469 153.043 107.922 153.029 105.998L152.851 82.7151C137.061 96.9307 117.225 108.347 98.0123 113.314C76.359 118.913 54.5731 116.469 40.9795 99.1868C36.0284 92.8922 33.3336 86.3022 32.379 79.7991C24.0431 76.9156 16.3504 71.8185 10.5316 63.9024C1.00655 50.9442 -2.99208 31.1175 2.46841 2.8239C2.83291 0.935279 4.66077 -0.300532 6.55105 0.0636381ZM38.9711 74.4741C42.2611 75.2589 45.6555 75.6936 49.0752 75.8124C61.7989 76.2546 74.3892 72.2903 82.3414 66.2645C86.3069 63.2597 88.906 59.9094 89.9982 56.567C91.0504 53.3471 90.8093 49.8426 88.5489 46.0681C84.151 38.7243 78.0249 36.1083 71.5915 36.4775C64.9174 36.8604 57.5729 40.5062 51.4542 46.4651C43.9859 53.7385 38.8557 63.8668 38.9711 74.4741Z'
      fill='#24FFB5'
    />
  </svg>
);

const Memo = memo(OkIcon);
export { Memo as OkIcon };
