import { memo, SVGProps } from 'react';

const VectorIcon5 = (props: SVGProps<SVGSVGElement>) => (
  <svg preserveAspectRatio='none' viewBox='0 0 105 105' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M43.4583 58.1875L51.0417 65.7708C52.1111 66.8403 53.4722 67.375 55.125 67.375C56.7778 67.375 58.1389 66.8403 59.2083 65.7708L75.8333 49.1458V52.6458C75.8333 54.2986 76.3933 55.6597 77.5133 56.7292C78.6333 57.7986 80.0178 58.3333 81.6667 58.3333C83.3194 58.3333 84.7058 57.7753 85.8258 56.6592C86.9458 55.5431 87.5039 54.1567 87.5 52.5V35C87.5 33.3472 86.94 31.9608 85.82 30.8408C84.7 29.7208 83.3155 29.1628 81.6667 29.1667H64.0208C62.3681 29.1667 61.0069 29.7267 59.9375 30.8467C58.8681 31.9667 58.3333 33.3511 58.3333 35C58.3333 36.6528 58.8933 38.0392 60.0133 39.1592C61.1333 40.2792 62.5178 40.8372 64.1667 40.8333H67.5208L55.125 53.375L47.5417 45.7917C46.4722 44.625 45.1111 44.0417 43.4583 44.0417C41.8056 44.0417 40.4444 44.625 39.375 45.7917L21.5833 63.5833C20.4167 64.6528 19.8333 66.0139 19.8333 67.6667C19.8333 69.3194 20.4167 70.6806 21.5833 71.75C22.6528 72.9167 24.0139 73.5 25.6667 73.5C27.3195 73.5 28.6806 72.9167 29.75 71.75L43.4583 58.1875ZM11.6667 105C8.45834 105 5.71084 103.857 3.42418 101.57C1.13751 99.2833 -0.00387898 96.5378 9.90379e-06 93.3333V11.6667C9.90379e-06 8.45834 1.14334 5.71084 3.43001 3.42418C5.71668 1.13751 8.46223 -0.00387898 11.6667 9.90379e-06H93.3333C96.5417 9.90379e-06 99.2892 1.14334 101.576 3.43001C103.862 5.71668 105.004 8.46223 105 11.6667V93.3333C105 96.5417 103.857 99.2892 101.57 101.576C99.2833 103.862 96.5378 105.004 93.3333 105H11.6667ZM11.6667 93.3333H93.3333V11.6667H11.6667V93.3333Z'
      fill='#353535'
    />
  </svg>
);

const Memo = memo(VectorIcon5);
export { Memo as VectorIcon5 };
