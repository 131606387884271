import { memo, SVGProps } from 'react';

const VectorIcon6 = (props: SVGProps<SVGSVGElement>) => (
  <svg preserveAspectRatio='none' viewBox='0 0 46 25' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M41.2458 0.0373955C40.6353 0.0436374 40.0355 0.198855 39.4987 0.489563C38.9618 0.780271 38.504 1.19767 38.1651 1.70551C37.8262 2.21335 37.6165 2.79627 37.554 3.40361C37.4916 4.01095 37.5784 4.62435 37.8069 5.19053L29.9955 13.1595C29.1125 12.822 28.1344 12.8318 27.2584 13.1868L21.5619 7.60248C21.7795 7.03174 21.8545 6.41645 21.7802 5.81014C21.7059 5.20382 21.4847 4.62484 21.1356 4.12352C20.7866 3.62221 20.3204 3.21376 19.7776 2.93368C19.2348 2.6536 18.6317 2.51037 18.0209 2.51646C17.4101 2.52255 16.8101 2.67776 16.2729 2.96861C15.7358 3.25945 15.2778 3.67712 14.9389 4.18529C14.5999 4.69346 14.3902 5.27674 14.3281 5.88441C14.2659 6.49209 14.3531 7.10576 14.5821 7.67205L5.28586 17.1558C4.48082 16.8492 3.59476 16.8292 2.77667 17.0991C1.95858 17.369 1.25837 17.9123 0.793757 18.6378C0.329147 19.3632 0.128479 20.2265 0.225495 21.0825C0.322511 21.9385 0.711293 22.735 1.32647 23.3381C1.94165 23.9411 2.7457 24.314 3.60344 24.3939C4.46117 24.4739 5.32027 24.256 6.03628 23.777C6.7523 23.298 7.28155 22.5871 7.53507 21.7638C7.78858 20.9404 7.75088 20.0549 7.42832 19.2561L16.7245 9.7723C17.6075 10.1098 18.5856 10.1 19.4617 9.74502L25.1582 15.3293C24.9405 15.9 24.8656 16.5153 24.9398 17.1216C25.0141 17.728 25.2354 18.3069 25.5844 18.8083C25.9334 19.3096 26.3996 19.718 26.9425 19.9981C27.4853 20.2782 28.0883 20.4214 28.6991 20.4153C29.3099 20.4092 29.91 20.254 30.4471 19.9632C30.9843 19.6723 31.4422 19.2547 31.7812 18.7465C32.1201 18.2383 32.3298 17.655 32.392 17.0474C32.4542 16.4397 32.367 15.826 32.138 15.2597L39.9493 7.29078C40.4648 7.48737 41.0172 7.56781 41.5673 7.52637C42.1174 7.48492 42.6516 7.3226 43.1317 7.05099C43.6119 6.77938 44.0262 6.40517 44.3451 5.95504C44.6641 5.50491 44.8798 4.98995 44.9768 4.44688C45.0739 3.90382 45.0499 3.34602 44.9067 2.81328C44.7634 2.28053 44.5044 1.78595 44.1481 1.36482C43.7917 0.943684 43.3468 0.606366 42.8452 0.376915C42.3435 0.147465 41.7974 0.0315358 41.2458 0.0373955Z'
      fill='#353535'
    />
  </svg>
);

const Memo = memo(VectorIcon6);
export { Memo as VectorIcon6 };
