import React, { useState, memo,MouseEvent} from 'react';
import type { FC, ReactNode } from 'react';
import axios from 'axios';
import qs from 'qs';


import resets from '../../../_resets.module.css';
import classes from './SendEmailForm.module.css';
import { VectorIcon } from '../Send_Property1Default/VectorIcon';
import  buttonCss  from '../Send_Property1Default/Send_Property1Default.module.css';

interface Props {
  className?: string;
  classes?: {
    root?: string;
  };
  text?: {
    name?: ReactNode;
  };
}

export const SendEmailForm: FC<Props> = memo(function SendEmailForm(props = {}) {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const phoneRegex = /^\d{3}-\d{3}-\d{4}$/;// Bu örnek, tam olarak 10 basamaklı bir telefon numarasını kabul eder.
  const nameRegex = /^.{3,}$/; 
  const messageRegex = /^[\s\S]{50,}$/;

  const [name, setName] = useState<string>('');
  const [nameError, setNameError] = useState<boolean>(false);

  const [email, setEmail] = useState<string>('');
  const [emailError, setEmailError] = useState<boolean>(false);

  const [phone, setPhone] = useState<string>('');
  const [phoneError, setPhoneError] = useState<boolean>(false);

  const [message, setMessage] = useState<string>('');
  const [messageError, setMessageError] = useState<boolean>(false);

  const [fill, setFill] = useState<string>('#353535');


  const handleSendButton = (e: MouseEvent<HTMLButtonElement>): void => {
    // name validation
    if (name === '' || !nameRegex.test(name.trim())) {
        setNameError(true);
    } else {
        setNameError(false);
    }

    // email validation
    if (email === '' || !emailRegex.test(email)) {
        setEmailError(true);
    } else {
        setEmailError(false);
    }

    // phone validation
    if (phone === '' || !phoneRegex.test(phone)) {
        setPhoneError(true);
    } else {
        setPhoneError(false);
    }

    // message validation
    if (message === '' || !messageRegex.test(message.trim())) {
        setMessageError(true);
    } else {
        setMessageError(false);
    }
  };

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
  let value = e.target.value;
  setName(value);
  setNameError(!nameRegex.test(value.trim())); // trim() is used to remove leading/trailing white spaces
};

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setEmail(value);
    setEmailError(!emailRegex.test(value));
  };
  
  const handlePhoneChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const input = e.target.value;
    const formattedInput = input
      .replace(/\D/g, '')
      .slice(0, 10)
      .replace(/(\d{3})(\d{1,3})(\d{1,4})/, '$1-$2-$3');
  
    setPhone(formattedInput);
    setPhoneError(!phoneRegex.test(formattedInput));
  };

  const handleMessageChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    let value = e.target.value;
    value = value.replace(/ +(?= )/g,'');
    setMessage(value);
    setMessageError(!messageRegex.test(value.trim())); // trim() is used to remove leading/trailing white spaces

    console.log("Message Length: ", value.length); // Prints the length of the message
  };
  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    // const formData = new FormData();
    // formData.append('fullname', name);
    // formData.append('email', email);
    // formData.append('number', phone);
    // formData.append('message', message);
      
    const formData = {
      fullname: name,
      email,
      number: phone,
      message
    };
    try {
      // Send the form data to your backend using Axios
      // 'http://ronobt.com.tr/script.php' please, Use this address to test from your localhost
      const response = await axios.post('https://ronobt.com.tr/script.php', qs.stringify(formData), {
      //const response = await axios.post('./script.php', qs.stringify(formData), {

        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      });
      console.log(response);
      console.log(response.data); // Handle the response from the backend

      // Reset the form after successful submission
      setName('');
      setEmail('');
      setPhone('');
      setMessage('');

      window.location.reload();

      if(response.status === 200)
        window.alert("E-mail was successfully sent!")
    } catch (error) {
      console.error(error); // Handle any error that occurs during the request
    }

    console.log("Form was submitted");
  };

  return (
    <div className={`${resets.clapyResets} ${props.classes?.root || ''} ${props.className || ''} ${classes.root}`}>
      <form onSubmit={handleSubmit} className={classes.form}>
        <div className={`${classes.formGroup} ${nameError ? classes.error : ''}`}>
          <label>Name:</label>
          <input type="text" value={name} maxLength={35} placeholder="Click to type your fullname" required onChange={(e) => handleNameChange(e)} />
        </div>
        <div className={`${classes.formGroup} ${emailError ? classes.error : ''}`}>
          <label>Email:</label>
          <input type="email" value={email} maxLength={91} placeholder="Click to type your e-mail" required onChange={(e) => handleEmailChange(e)}  />
        </div>
        <div className={`${classes.formGroup} ${phoneError ? classes.error : ''}`}>
          <label>Phone:</label>
          <input type="tel" value={phone} pattern="[0-9]*" placeholder="Click to type your phone number" required onChange={(e) => handlePhoneChange(e)}  />
        </div>
        <div className={`${classes.formGroup} ${messageError ? classes.error : ''}`}>
          <label>Message:</label>
          <textarea value={message} maxLength={1000} placeholder="Click to enter your message" required onChange={(e) => handleMessageChange(e)} />
          <div className= {classes.textLengthContainer}>
          {`${message.length}/1000`}
          </div>
        </div>
        <div className={classes.buttonContainer}>
          <button
            type="submit"
            onMouseEnter={() => setFill('#DCDCDC')}
            onMouseLeave={() => setFill('#353535')}
            onClick={handleSendButton}
          >
            Send<VectorIcon className={buttonCss.icon} fill={fill} />
          </button>
        </div>
      </form>
    </div>
  );
});