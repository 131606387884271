import { memo, SVGProps } from 'react';

const OkIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg preserveAspectRatio='none' viewBox='0 0 67 49' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M2.73896 0.0266067C3.52927 0.178864 4.04641 0.942407 3.89402 1.73203C1.70089 13.0958 3.41945 20.4591 6.75241 24.9934C8.53064 27.4126 10.8286 29.1081 13.3906 30.1905C13.6489 25.2164 16.1454 20.5872 19.4782 17.3414C22.3941 14.5017 26.0942 12.5543 29.7649 12.3437C33.5362 12.1272 37.1264 13.7637 39.523 17.7656C40.8824 20.0357 41.1112 22.3732 40.3985 24.5542C39.7025 26.684 38.1543 28.535 36.1878 30.0251C32.2637 32.9985 26.3206 34.8123 20.4167 34.6071C19.1728 34.5639 17.9218 34.4304 16.6846 34.1964C17.1457 36.049 18.0305 37.8971 19.425 39.67C24.0872 45.5974 31.7416 46.7561 40.2481 44.5568C47.6522 42.6424 55.4097 38.2309 61.6566 32.686L52.2329 32.7579C51.428 32.764 50.7706 32.1171 50.7644 31.313C50.7583 30.5088 51.4058 29.852 52.2106 29.8458L65.3264 29.7458C66.1313 29.7397 66.7887 30.3866 66.7949 31.1907L66.895 44.295C66.9011 45.0991 66.2537 45.756 65.4488 45.7621C64.644 45.7683 63.9865 45.1214 63.9804 44.3172L63.906 34.5827C57.3046 40.5262 49.0112 45.2992 40.9784 47.376C31.9252 49.7167 22.8167 48.6951 17.1333 41.4694C15.0633 38.8377 13.9366 36.0824 13.5375 33.3635C10.0523 32.1579 6.83599 30.0269 4.40318 26.7172C0.420834 21.2995 -1.25097 13.0101 1.03203 1.18066C1.18442 0.391035 1.94864 -0.125651 2.73896 0.0266067ZM16.2936 31.1372C17.6691 31.4653 19.0883 31.647 20.518 31.6967C25.8377 31.8816 31.1017 30.2241 34.4265 27.7048C36.0844 26.4485 37.1711 25.0477 37.6277 23.6503C38.0676 22.3041 37.9668 20.8389 37.0218 19.2608C35.183 16.1904 32.6217 15.0967 29.932 15.251C27.1416 15.4111 24.0709 16.9354 21.5127 19.4268C18.3902 22.4678 16.2453 26.7023 16.2936 31.1372Z'
      fill='#24FFB5'
    />
  </svg>
);

const Memo = memo(OkIcon);
export { Memo as OkIcon };
