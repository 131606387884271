import { memo, SVGProps } from 'react';

const Ellipse1Icon = (props: SVGProps<SVGSVGElement>) => (
  <svg preserveAspectRatio='none' viewBox='0 0 360 360' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M360 180C360 279.411 279.411 360 180 360C80.5887 360 0 279.411 0 180C0 80.5887 80.5887 0 180 0C279.411 0 360 80.5887 360 180ZM90 180C90 229.706 130.294 270 180 270C229.706 270 270 229.706 270 180C270 130.294 229.706 90 180 90C130.294 90 90 130.294 90 180Z'
      fill='#24FFB5'
    />
  </svg>
);

const Memo = memo(Ellipse1Icon);
export { Memo as Ellipse1Icon };
