import { memo, SVGProps } from 'react';

const VectorIcon5 = (props: SVGProps<SVGSVGElement>) => (
  <svg preserveAspectRatio='none' viewBox='0 0 70 70' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M28.9722 38.7917L34.0278 43.8472C34.7407 44.5602 35.6481 44.9167 36.75 44.9167C37.8519 44.9167 38.7593 44.5602 39.4722 43.8472L50.5556 32.7639V35.0972C50.5556 36.1991 50.9289 37.1065 51.6756 37.8194C52.4222 38.5324 53.3452 38.8889 54.4444 38.8889C55.5463 38.8889 56.4705 38.5169 57.2172 37.7728C57.9639 37.0287 58.3359 36.1044 58.3333 35V23.3333C58.3333 22.2315 57.96 21.3072 57.2133 20.5606C56.4667 19.8139 55.5437 19.4419 54.4444 19.4444H42.6806C41.5787 19.4444 40.6713 19.8178 39.9583 20.5644C39.2454 21.3111 38.8889 22.2341 38.8889 23.3333C38.8889 24.4352 39.2622 25.3594 40.0089 26.1061C40.7556 26.8528 41.6785 27.2248 42.7778 27.2222H45.0139L36.75 35.5833L31.6944 30.5278C30.9815 29.75 30.0741 29.3611 28.9722 29.3611C27.8704 29.3611 26.963 29.75 26.25 30.5278L14.3889 42.3889C13.6111 43.1018 13.2222 44.0093 13.2222 45.1111C13.2222 46.213 13.6111 47.1204 14.3889 47.8333C15.1019 48.6111 16.0093 49 17.1111 49C18.213 49 19.1204 48.6111 19.8333 47.8333L28.9722 38.7917ZM7.77778 70C5.63889 70 3.80723 69.2378 2.28278 67.7133C0.758339 66.1889 -0.00258599 64.3585 6.60253e-06 62.2222V7.77778C6.60253e-06 5.63889 0.762229 3.80723 2.28667 2.28278C3.81112 0.758339 5.64149 -0.00258599 7.77778 6.60253e-06H62.2222C64.3611 6.60253e-06 66.1928 0.762229 67.7172 2.28667C69.2417 3.81112 70.0026 5.64149 70 7.77778V62.2222C70 64.3611 69.2378 66.1928 67.7133 67.7172C66.1889 69.2417 64.3585 70.0026 62.2222 70H7.77778ZM7.77778 62.2222H62.2222V7.77778H7.77778V62.2222Z'
      fill='#353535'
    />
  </svg>
);

const Memo = memo(VectorIcon5);
export { Memo as VectorIcon5 };
