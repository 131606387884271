import { memo, SVGProps } from 'react';

const Ellipse1Icon = (props: SVGProps<SVGSVGElement>) => (
  <svg preserveAspectRatio='none' viewBox='0 0 955 955' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M955 477.5C955 741.216 741.216 955 477.5 955C213.784 955 0 741.216 0 477.5C0 213.784 213.784 0 477.5 0C741.216 0 955 213.784 955 477.5ZM238.75 477.5C238.75 609.358 345.642 716.25 477.5 716.25C609.358 716.25 716.25 609.358 716.25 477.5C716.25 345.642 609.358 238.75 477.5 238.75C345.642 238.75 238.75 345.642 238.75 477.5Z'
      fill='#24FFB5'
    />
  </svg>
);

const Memo = memo(Ellipse1Icon);
export { Memo as Ellipse1Icon };
