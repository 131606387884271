import { memo, SVGProps } from 'react';

const Ellipse2Icon = (props: SVGProps<SVGSVGElement>) => (
  <svg preserveAspectRatio='none' viewBox='0 0 180 180' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M180 90C180 139.706 139.706 180 90 180C40.2944 180 0 139.706 0 90C0 40.2944 40.2944 0 90 0C139.706 0 180 40.2944 180 90ZM45 90C45 114.853 65.1472 135 90 135C114.853 135 135 114.853 135 90C135 65.1472 114.853 45 90 45C65.1472 45 45 65.1472 45 90Z'
      fill='#24FFB5'
    />
  </svg>
);

const Memo = memo(Ellipse2Icon);
export { Memo as Ellipse2Icon };
