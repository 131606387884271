import { memo, SVGProps } from 'react';

const Vector1Icon = (props: SVGProps<SVGSVGElement>) => (
  <svg preserveAspectRatio='none' viewBox='0 0 34 17' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path d='M34 0L17 17L0 0' stroke='#24FFB5' strokeWidth={5} />
  </svg>
);

const Memo = memo(Vector1Icon);
export { Memo as Vector1Icon };
