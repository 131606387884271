import React, { useState } from 'react';
import { MenuLines } from './MenuLines/MenuLines';
import { MenuComponent } from './MenuComponent/MenuComponent';
import classes from '../Mobile320px/Mobile320px.module.css';
import { Vector } from './Vector/Vector';
import { VectorIcon7 } from './VectorIcon7';

function Header() {
  const [isMenuVisible, setIsMenuVisible] = useState(false);

  const toggleMenuVisibility = () => {
    setIsMenuVisible((prevIsMenuVisible) => !prevIsMenuVisible);
  };

  return (
    <div>
      <div className={classes.baslK}>
        <div className={classes.frame11}></div>
        <Vector
          className={classes.vector2}
          swap={{
            vector: <VectorIcon7 className={classes.icon3} />,
          }}
        />
        <MenuLines
          className={classes.component14}
          onClick={toggleMenuVisibility} // Pass the function to toggle menu visibility to the child component
          
        />
      </div>
      {isMenuVisible && <MenuComponent />} {/* Show MenuComponent based on state */}
    </div>
  );
}

export default Header;
