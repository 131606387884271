import type { FC } from 'react';
import { memo } from 'react';

import resets from '../../_resets.module.css';
import Footer from '../Shared/Footer/Footer';
import Header from '../Shared/Header';
import { OkIcon } from './OkIcon';
import { SendEmailForm } from './SendEmailForm/SendEmailForm';
import classes from './TabletContactPage.module.css';

interface Props {
  className?: string;
}
/* @figmaId 270:224 */
export const TabletContactPage: FC<Props> = memo(function TabletContactPage(props = {}) {
  return (
    <div className={`${resets.clapyResets} ${classes.root}`}>
      <div className={classes.contactUs}>
        <div className={classes.textBlock}>Contact</div>
        <div className={classes.textBlock2}>Us.</div>
      </div>
      <div className={classes.ok}>
        <OkIcon className={classes.icon2} />
      </div>
      <Header/>
      <Footer/>
      <SendEmailForm/>
    </div>
  );
});
