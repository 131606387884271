import { memo, SVGProps } from 'react';

const Uber_logo_black_rgbOptimizedIc = (props: SVGProps<SVGSVGElement>) => (
  <svg preserveAspectRatio='none' viewBox='0 0 106 66' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M30.0044 41.4014C32.9811 41.4014 35.288 39.0467 35.288 35.5583V21.9863H38.5116V43.9777H35.3182V41.9373C33.8727 43.4779 31.8781 44.3539 29.6367 44.3539C25.033 44.3539 21.4972 40.9274 21.4972 35.749V21.9915H24.7207V35.5635C24.7207 39.1085 26.9924 41.4014 30.0044 41.4014ZM40.9041 21.9863H44.0068V29.9987C45.4171 28.5199 47.3865 27.613 49.5624 27.613C54.166 27.613 57.7925 31.3487 57.7925 36.0015C57.7925 40.6182 54.1711 44.359 49.5624 44.359C47.3814 44.359 45.3868 43.447 43.9715 41.9734V43.9829H40.899V21.9863H40.9041ZM49.3458 41.556C52.2923 41.556 54.7201 39.0724 54.7201 35.9963C54.7201 32.8841 52.2923 30.4366 49.3458 30.4366C46.369 30.4366 43.9413 32.8893 43.9413 35.9963C43.9413 39.0776 46.3388 41.556 49.3458 41.556ZM67.0653 27.6439C71.5783 27.6439 74.8976 31.1941 74.8976 35.9706V37.0062H62.1544C62.5826 39.6135 64.7031 41.5612 67.3424 41.5612C69.1556 41.5612 70.6919 40.8089 71.8554 39.2064L74.0968 40.9016C72.5303 43.04 70.1982 44.323 67.3424 44.323C62.643 44.323 59.0215 40.7419 59.0215 35.9654C59.0215 31.4414 62.4919 27.6439 67.0653 27.6439ZM62.2149 34.4918H71.7647C71.2409 32.0391 69.3067 30.4057 67.0049 30.4057C64.7031 30.4057 62.7689 32.0391 62.2149 34.4918ZM83.2184 30.7819C81.1936 30.7819 79.7178 32.3843 79.7178 34.8679V43.9777H76.6152V27.9582H79.6876V29.9368C80.4532 28.6487 81.7124 27.8346 83.435 27.8346H84.5079V30.787H83.2184V30.7819Z'
      fill='black'
    />
  </svg>
);

const Memo = memo(Uber_logo_black_rgbOptimizedIc);
export { Memo as Uber_logo_black_rgbOptimizedIc };
