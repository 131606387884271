import { memo } from 'react';
import type { FC } from 'react';

import resets from '../../../_resets.module.css';
import classes from './Logo.module.css';
import { VectorIcon } from './VectorIcon';
import { useNavigate } from 'react-router-dom';

interface Props {
  className?: string;
}
/* @figmaId 152:231 */
export const Logo: FC<Props> = memo(function Logo(props = {}) {
  
  const navigate = useNavigate();
  function goToMain() {
    navigate('/');
    window.scrollTo(0, 0);
  }

  return (
    <div className={`${resets.clapyResets} ${classes.root} ${resets.clickable}`} onClick={goToMain}>
      <div className={classes.vector}>
        <VectorIcon className={classes.icon} />
      </div>
    </div>
  );
});
