import { memo } from 'react';
import type { FC } from 'react';

import resets from '../../_resets.module.css';
import classes from './About.module.css';
import { AiBrainIcon } from './AiBrainIcon';
import { ContactButton_Property1Default } from './ContactButton_Property1Default/ContactButton_Property1Default';
import { Frame18_Property1Default } from './Frame18_Property1Default/Frame18_Property1Default';
import { Logo } from './Logo/Logo';
import { Footer } from '../Shared/Footer/Footer';
interface Props {
  className?: string;
}
/* @figmaId 354:223 */
export const DesktopAbout: FC<Props> = memo(function About(props = {}) {
  return (
    <div className={`${resets.clapyResets} ${classes.root} ${classes.customScrollbar}`} >
      <Footer/>
      <div className={classes.aIBRAIN}>
        <AiBrainIcon className={classes.icon2} />
      </div>
      <div className={classes.ronoWasEstablishedInAnkaraIn20}>
        <p className={classes.labelWrapper3}>
          <span className={classes.label4}>
            {' '}
            Rono was established in Ankara in 2022. It continues to develop projects in the field of data analytics for
            various public and private sector institutions and provides training in data analytics. With its young team
            that develops solutions in{' '}
          </span>
          <span className={classes.label5}>
            Data Analytics, Artificial Intelligence Applications, Data Collection, and Mobile Software
          </span>
          <span className={classes.label6}>, it carries out R&amp;D activities within the scope of TEKMER.</span>
        </p>
      </div>
      <div className={classes.aboutUs}>About Us.</div>
      <div className={classes.hEADER}>
        <ContactButton_Property1Default
          className={classes.contactButton}
          text={{
            contactUs: <div className={classes.contactUs}>Contact Us</div>,
          }}
        />
        <Logo />
        <Frame18_Property1Default className={classes.frame18} />
      </div>
    </div>
  );
});
