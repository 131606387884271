import { memo, SVGProps } from 'react';

const VectorIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg preserveAspectRatio='none' viewBox='0 0 37 55' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M19.1546 0.00520603C17.2576 -0.0376811 15.2978 0.184001 13.2908 0.685404C4.00991 3.00326 -6.37899 14.9398 4.96495 28.7031L11.7358 36.8978L13.6472 39.23C14.9301 40.7549 17.0294 40.7277 18.5715 39.4567C20.1213 38.1792 20.5619 36.0842 19.2842 34.5333C19.2826 34.5312 18.7424 33.8775 18.5067 33.594H18.5391C18.0497 33.0054 17.9435 32.9067 17.4376 32.2984C14.9871 29.3518 12.1894 25.9499 12.1894 25.9499C7.26381 19.9732 9.46158 12.5895 16.1417 8.39431C22.0275 4.69792 32.8285 6.01816 36.5515 12.0868C34.2067 5.1675 27.375 0.19105 19.1546 0.00520603ZM20.5153 14.4513C19.6401 14.4215 18.7263 14.7551 17.9559 15.3906C16.4035 16.6694 15.9668 18.767 17.2432 20.314C17.2458 20.3179 17.4 20.5096 17.6644 20.8322C20.6006 24.3593 24.5 29.0918 24.5 29.0918C29.4269 35.0684 27.2278 42.4198 20.5476 46.615C14.6632 50.3101 3.86088 48.9872 0.137882 42.9225C3.02376 51.4346 12.696 56.9993 23.3985 54.3239C32.6821 52.0021 43.0684 40.0993 31.7244 26.3386L23.7873 16.751L22.8802 15.6174C22.2387 14.8549 21.3904 14.4811 20.5153 14.4513Z'
      fill='#353535'
    />
  </svg>
);

const Memo = memo(VectorIcon);
export { Memo as VectorIcon };
