import { memo, SVGProps } from 'react';

const Uber_logo_black_rgbOptimizedIc = (props: SVGProps<SVGSVGElement>) => (
  <svg preserveAspectRatio='none' viewBox='0 0 307 187' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M86.8995 117.304C95.5208 117.304 102.202 110.632 102.202 100.748V62.2944H111.538V124.603H102.29V118.822C98.1029 123.187 92.3261 125.669 85.8346 125.669C72.5013 125.669 62.2607 115.961 62.2607 101.289V62.309H71.5969V100.763C71.5969 110.807 78.176 117.304 86.8995 117.304ZM118.467 62.2944H127.454V84.996C131.538 80.8061 137.242 78.2367 143.544 78.2367C156.877 78.2367 167.38 88.821 167.38 102.004C167.38 115.085 156.892 125.684 143.544 125.684C137.227 125.684 131.451 123.1 127.351 118.924V124.618H118.453V62.2944H118.467ZM142.917 117.742C151.45 117.742 158.482 110.705 158.482 101.989C158.482 93.1715 151.45 86.237 142.917 86.237C134.295 86.237 127.264 93.1861 127.264 101.989C127.264 110.72 134.208 117.742 142.917 117.742ZM194.236 78.3243C207.307 78.3243 216.92 88.383 216.92 101.916V104.851H180.013C181.253 112.238 187.395 117.756 195.039 117.756C200.29 117.756 204.74 115.625 208.109 111.085L214.601 115.888C210.064 121.946 203.31 125.582 195.039 125.582C181.428 125.582 170.94 115.435 170.94 101.902C170.94 89.0838 180.991 78.3243 194.236 78.3243ZM180.188 97.7265H207.847C206.33 90.7773 200.728 86.1494 194.061 86.1494C187.395 86.1494 181.793 90.7773 180.188 97.7265ZM241.019 87.2151C235.155 87.2151 230.881 91.7554 230.881 98.7922V124.603H221.895V79.2148H230.793V84.8209C233.011 81.1711 236.658 78.8644 241.647 78.8644H244.754V87.2297H241.019V87.2151Z'
      fill='black'
    />
  </svg>
);

const Memo = memo(Uber_logo_black_rgbOptimizedIc);
export { Memo as Uber_logo_black_rgbOptimizedIc };
