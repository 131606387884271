import { memo } from 'react';
import type { FC } from 'react';

import resets from '../../../_resets.module.css';
import classes from './Component3.module.css';
import { MaterialSymbolsMailOutlineIcon } from './MaterialSymbolsMailOutlineIcon';

interface Props {
  className?: string;
  classes?: {
    root?: string;
  };
}
/* @figmaId 135:54 */
export const Component3: FC<Props> = memo(function Component3(props = {}) {
  return (
    <div className={`${resets.clapyResets} ${props.classes?.root || ''} ${props.className || ''} ${classes.root}`}>
      <div className={classes.materialSymbolsMailOutline}>
        <MaterialSymbolsMailOutlineIcon className={classes.icon} />
      </div>
      <div className={classes.infoRonobtComTr}>info@ronobt.com.tr</div>
    </div>
  );
});
