import { memo, SVGProps } from 'react';

const VectorIcon8 = (props: SVGProps<SVGSVGElement>) => (
  <svg preserveAspectRatio='none' viewBox='0 0 40 40' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M6.66667 31.1111H33.3333L25 20L18.3333 28.8889L13.3333 22.2222L6.66667 31.1111ZM6.66667 20C10.3704 20 13.5185 18.7037 16.1111 16.1111C18.7037 13.5185 20 10.3704 20 6.66667H16.2222C16.2222 9.33334 15.2963 11.5926 13.4444 13.4444C11.5926 15.2963 9.33334 16.2222 6.66667 16.2222V20ZM6.66667 12.4444C8.25926 12.4444 9.60223 11.8793 10.6956 10.7489C11.7889 9.61852 12.3348 8.25778 12.3333 6.66667H6.66667V12.4444ZM4.44445 40C3.22223 40 2.17556 39.5644 1.30445 38.6933C0.433337 37.8222 -0.00147771 36.7763 3.77287e-06 35.5556V4.44445C3.77287e-06 3.22223 0.435559 2.17556 1.30667 1.30445C2.17778 0.433337 3.22371 -0.00147771 4.44445 3.77287e-06H35.5556C36.7778 3.77287e-06 37.8244 0.435559 38.6956 1.30667C39.5667 2.17778 40.0015 3.22371 40 4.44445V35.5556C40 36.7778 39.5644 37.8244 38.6933 38.6956C37.8222 39.5667 36.7763 40.0015 35.5556 40H4.44445ZM4.44445 35.5556H35.5556V4.44445H4.44445V35.5556Z'
      fill='#353535'
    />
  </svg>
);

const Memo = memo(VectorIcon8);
export { Memo as VectorIcon8 };
