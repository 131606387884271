import type { FC } from 'react';
import { memo } from 'react';

import resets from '../../_resets.module.css';
import Footer from '../Shared/Footer/Footer';
import Header from '../Shared/Header';
import { Component1 } from './Component1/Component1';
import { Ellipse1Icon } from './Ellipse1Icon';
import { HazineBakanlG1OptimizedIcon } from './HazineBakanlG1OptimizedIcon';
import { Hopi1OptimizedIcon } from './Hopi1OptimizedIcon';
import { MainpageDataIllustrationIcon } from './MainpageDataIllustrationIcon';
import { MigrosOptimizedIcon } from './MigrosOptimizedIcon';
import { NgLogo1OptimizedIcon } from './NgLogo1OptimizedIcon';
import { SasLogoOptimizedIcon } from './SasLogoOptimizedIcon';
import { StarIcon } from './StarIcon';
import { Subjects_Property1Frame17 } from './Subjects_Property1Frame17/Subjects_Property1Frame17';
import classes from './TabletMain.module.css';
import { TicaretBakanlGLogo1OptimizedIc } from './TicaretBakanlGLogo1OptimizedIc';
import { Uber_logo_black_rgbOptimizedIc } from './Uber_logo_black_rgbOptimizedIc';
import { Vector1Icon } from './Vector1Icon';
import { VectorIcon2 } from './VectorIcon2';
import { VectorIcon3 } from './VectorIcon3';
import { VectorIcon4 } from './VectorIcon4';
import { VectorIcon5 } from './VectorIcon5';
import { VectorIcon6 } from './VectorIcon6';
import { VectorIcon7 } from './VectorIcon7';
import { VectorIcon8 } from './VectorIcon8';
import { VectorStrokeIcon } from './VectorStrokeIcon';

interface Props {
  className?: string;
}
/* @figmaId 270:157 */
export const TabletMain: FC<Props> = memo(function TabletMain(props = {}) {
  return (
    <div className={`${resets.clapyResets} ${classes.root}`}>
      {/* <div className={classes.end}>
        <div className={classes.ankaraTEKMERCevizlidereMahMevl}>
          <p className={classes.labelWrapper}>
            <span className={classes.label}>Ankara TEKMER</span>
            <span className={classes.label2}> </span>
          </p>
          <div className={classes.textBlock}>Cevizlidere Mah. Mevlana Bul. No:221</div>
          <div className={classes.textBlock2}>İç Kapı No: 105</div>
          <div className={classes.textBlock3}>
            <p className={classes.labelWrapper2}>
              <span className={classes.label3}>ÇANKAYA/ANKARA</span>
            </p>
          </div>
        </div>
        <div className={classes.computerTech}>Computer Tech.</div>
        <div className={classes.vector3}>
          <VectorIcon className={classes.icon4} />
        </div>
        <div className={classes.frame7}>
          <Frame7Icon className={classes.icon5} />
        </div>
        <div className={classes.frame8}>
          <Frame8Icon className={classes.icon6} />
        </div>
        <div className={classes.frame9}>
          <Frame9Icon className={classes.icon7} />
        </div>
        <div className={classes.frame10}>
          <Frame10Icon className={classes.icon8} />
        </div>
        <Component3 className={classes.component3} />
      </div> */}
      <div className={classes.whiteRectangle}></div>
      <div className={classes.star}>
        <StarIcon className={classes.icon9} />
      </div>
      <div className={classes.rectangle2}></div>
      <div className={classes.ellipse1}>
        <Ellipse1Icon className={classes.icon10} />
      </div>
      <div className={classes.decideThroughData}>
        <div className={classes.textBlock4}>Decide</div>
        <div className={classes.textBlock5}>Through</div>
        <div className={classes.textBlock6}>Data.</div>
      </div>
      <div className={classes.solutions}>Solutions</div>
      <div className={classes.trainings}>Trainings</div>
      <div className={classes.services}>Services</div>
      <div className={classes.mainpageDataIllustration}>
        <MainpageDataIllustrationIcon className={classes.icon11} />
      </div>
      <div className={classes.satelliteImagerySolutions}>
        <div className={classes.satelliteImageryAnalysis}>Satellite Imagery Analysis.</div>
        <div className={classes.weUseSatelliteImageryToTrackVe}>
          {' '}
          We use satellite imagery to track vegetation, agriculture, water resources, buildings &amp; movable assets.
        </div>
        <div className={classes.vector4}>
          <VectorIcon2 className={classes.icon12} />
        </div>
      </div>
      <div className={classes.satelliteImagerySolutions2}>
        <div className={classes.deepDiveAnalysisFromSocialMedi}>
          <div className={classes.textBlock7}>Deep Dive Analysis</div>
          <div className={classes.textBlock8}>From Social Media.</div>
        </div>
        <div className={classes.analysisOfTrendsHiddenNetworks}>
          {' '}
          Analysis of trends, hidden networks, disinformation and fake news, as well as finding most effective or
          influential.
        </div>
        <div className={classes.vectorStroke}>
          <VectorStrokeIcon className={classes.icon13} />
        </div>
      </div>
      <div className={classes.satelliteImagerySolutions3}>
        <div className={classes.digitalForensics}>
          <div className={classes.textBlock9}>Digital</div>
          <div className={classes.textBlock10}>Forensics.</div>
        </div>
        <div className={classes.identificationOfEditedImagesVi}>
          {' '}
          Identification of edited images, videos and sound files, handwritten signature authentication.
        </div>
        <div className={classes.vector5}>
          <VectorIcon3 className={classes.icon14} />
        </div>
      </div>
      <div className={classes.satelliteImagerySolutions4}>
        <div className={classes.networkAndLinkAnalysis}>Network and Link Analysis.</div>
        <div className={classes.identifyNetworksAndRelationsBe}>
          {' '}
          Identify networks and relations between people, enterprises and on online assets.
        </div>
        <div className={classes.vector6}>
          <VectorIcon4 className={classes.icon15} />
        </div>
      </div>
      <div className={classes.satelliteImagerySolutions5}>
        <div className={classes.dataScienceAnalyticsTrainings}>
          <div className={classes.textBlock11}>Data Science &amp;</div>
          <div className={classes.textBlock12}>Analytics Trainings.</div>
        </div>
        <div className={classes.fundamentalsOfDataManagementDa}>
          {' '}
          Fundamentals of data management, data analytics, common tools and applications.
        </div>
        <div className={classes.vector7}>
          <VectorIcon5 className={classes.icon16} />
        </div>
      </div>
      <div className={classes.satelliteImagerySolutions6}>
        <div className={classes.sASViyaTrainingsImplementation}>SAS Viya Trainings &amp; Implementations</div>
        <div className={classes.asAPartnerOfSASWeHelpSASCustom}>
          {' '}
          As a partner of SAS, we help SAS customers and integrators for their needs of SAS trainings and
          implementation.
        </div>
        <Component1
          className={classes.sasLogo}
          classes={{ vector: classes.vector }}
          swap={{
            vector: <VectorIcon6 className={classes.icon} />,
            vector2: (
              <div className={classes.vector}>
                <VectorIcon7 className={classes.icon2} />
              </div>
            ),
          }}
        />
      </div>
      <div className={classes.satelliteImagerySolutions7}>
        <div className={classes.analyticsProjectImplementation}>Analytics Project Implementations</div>
        <div className={classes.weHelpYourAnalyticProjectImple}>
          {' '}
          We help your analytic project implementations with open source technologies.
        </div>
        <div className={classes.vector8}>
          <VectorIcon8 className={classes.icon17} />
        </div>
      </div>
      <div className={classes.asRonoWeSupportOurCustomersToI}>
        <p className={classes.labelWrapper3}>
          <span className={classes.label4}> As Rono</span>
          <span className={classes.label5}>
            , we support our customers to implement data analytics projects, we give analytics trainings and develop
            solutions to be used as-a-service.{' '}
          </span>
        </p>
      </div>
      <div className={classes.rectangle14}></div>
      <Subjects_Property1Frame17 className={classes.subjects} />
      <div className={classes.vector1}>
        <Vector1Icon className={classes.icon18} />
      </div>
      <div className={classes.sasLogoOptimized}>
        <SasLogoOptimizedIcon className={classes.icon19} />
      </div>
      <div className={classes.whatsAppImage2023524At17571}></div>
      <div className={classes.uber_Logo_Black_RGBOptimized}>
        <Uber_logo_black_rgbOptimizedIc className={classes.icon20} />
      </div>
      <div className={classes.partnerships}>Partnerships</div>
      <div className={classes.rectangle18}></div>
      <div className={classes.references}>References</div>
      <div className={classes.rectangle19}></div>
      <div className={classes.hOPI1Optimized}>
        <Hopi1OptimizedIcon className={classes.icon21} />
      </div>
      <div className={classes.NgLogo1Optimized}>
        <NgLogo1OptimizedIcon className={classes.icon22} />
      </div>
      <div className={classes.mIGROSOptimized}>
        <MigrosOptimizedIcon className={classes.icon23} />
      </div>
      <div className={classes.dR1}></div>
      <div className={classes.hazineBakanlG1Optimized}>
        <HazineBakanlG1OptimizedIcon className={classes.icon24} />
      </div>
      <div className={classes.ticaretBakanlGLogo1Optimized}>
        <TicaretBakanlGLogo1OptimizedIc className={classes.icon25} />
      </div>
      <Header/>
      <Footer/>
      {/* <div className={classes.baslK}>
        <div className={classes.frame11}></div>
        <Vector
          className={classes.vector2}
          swap={{
            vector: <VectorIcon9 className={classes.icon3} />,
          }}
        />
        <Component14_Property1Default className={classes.component14} />
      </div> */}
    </div>
  );
});
