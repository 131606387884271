import { memo, SVGProps } from 'react';

interface VectorIcon extends SVGProps<SVGSVGElement> {
  fill?: string;
}

const VectorIcon = ({ fill = '#353535', ...props }: VectorIcon) => (
  <svg preserveAspectRatio='none' viewBox='0 0 18 20' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M1.43899 16.915L19.375 9.42072C19.5604 9.34373 19.7185 9.2153 19.8296 9.05141C19.9408 8.88753 20 8.69541 20 8.49897C20 8.30252 19.9408 8.1104 19.8296 7.94652C19.7185 7.78263 19.5604 7.6542 19.375 7.57721L1.43899 0.082971C1.28368 0.01694 1.11396 -0.0103635 0.945117 0.00352315C0.77628 0.0174098 0.613645 0.0720498 0.471884 0.162514C0.330124 0.252978 0.213699 0.376421 0.133111 0.521705C0.0525241 0.666989 0.0103104 0.829543 0.0102785 0.994704L0 5.61348C0 6.11443 0.380305 6.54525 0.89423 6.60537L15.4178 8.49897L0.89423 10.3825C0.380305 10.4527 0 10.8835 0 11.3844L0.0102785 16.0032C0.0102785 16.7146 0.760609 17.2055 1.43899 16.915Z'
      // fill='#353535'
      style={{ transition: 'fill 0.5s ease', fill }}
    />
  </svg>
);

const Memo = memo(VectorIcon);
export { Memo as VectorIcon };
