import { memo, SVGProps } from 'react';

const VectorIcon5 = (props: SVGProps<SVGSVGElement>) => (
  <svg preserveAspectRatio='none' viewBox='0 0 40 40' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M16.5556 22.1667L19.4444 25.0556C19.8519 25.463 20.3704 25.6667 21 25.6667C21.6296 25.6667 22.1481 25.463 22.5556 25.0556L28.8889 18.7222V20.0556C28.8889 20.6852 29.1022 21.2037 29.5289 21.6111C29.9556 22.0185 30.483 22.2222 31.1111 22.2222C31.7407 22.2222 32.2689 22.0096 32.6956 21.5844C33.1222 21.1593 33.3348 20.6311 33.3333 20V13.3333C33.3333 12.7037 33.12 12.1756 32.6933 11.7489C32.2667 11.3222 31.7393 11.1096 31.1111 11.1111H24.3889C23.7593 11.1111 23.2407 11.3244 22.8333 11.7511C22.4259 12.1778 22.2222 12.7052 22.2222 13.3333C22.2222 13.963 22.4356 14.4911 22.8622 14.9178C23.2889 15.3444 23.8163 15.557 24.4444 15.5556H25.7222L21 20.3333L18.1111 17.4444C17.7037 17 17.1852 16.7778 16.5556 16.7778C15.9259 16.7778 15.4074 17 15 17.4444L8.22222 24.2222C7.77778 24.6296 7.55556 25.1481 7.55556 25.7778C7.55556 26.4074 7.77778 26.9259 8.22222 27.3333C8.62963 27.7778 9.14815 28 9.77778 28C10.4074 28 10.9259 27.7778 11.3333 27.3333L16.5556 22.1667ZM4.44445 40C3.22223 40 2.17556 39.5644 1.30445 38.6933C0.433337 37.8222 -0.00147771 36.7763 3.77287e-06 35.5556V4.44445C3.77287e-06 3.22223 0.435559 2.17556 1.30667 1.30445C2.17778 0.433337 3.22371 -0.00147771 4.44445 3.77287e-06H35.5556C36.7778 3.77287e-06 37.8244 0.435559 38.6956 1.30667C39.5667 2.17778 40.0015 3.22371 40 4.44445V35.5556C40 36.7778 39.5644 37.8244 38.6933 38.6956C37.8222 39.5667 36.7763 40.0015 35.5556 40H4.44445ZM4.44445 35.5556H35.5556V4.44445H4.44445V35.5556Z'
      fill='#353535'
    />
  </svg>
);

const Memo = memo(VectorIcon5);
export { Memo as VectorIcon5 };
