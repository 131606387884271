import { memo, SVGProps } from 'react';

const VectorIcon6 = (props: SVGProps<SVGSVGElement>) => (
  <svg preserveAspectRatio='none' viewBox='0 0 23 35' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M11.9179 0.00323918C10.7376 -0.0234451 9.51826 0.114485 8.26953 0.426457C2.49495 1.86862 -3.96899 9.29548 3.08918 17.859L7.30199 22.9578L8.49126 24.4088C9.28947 25.3576 10.5956 25.3407 11.5551 24.5499C12.5194 23.755 12.7936 22.4515 11.9986 21.4866C11.9976 21.4852 11.6615 21.0785 11.5148 20.9021H11.535C11.2305 20.5359 11.1644 20.4745 10.8496 20.096C9.32495 18.2626 7.58419 16.146 7.58419 16.146C4.51952 12.4273 5.88697 7.83317 10.0433 5.22292C13.7055 2.92304 20.4258 3.74448 22.7423 7.52039C21.2833 3.21521 17.0327 0.118871 11.9179 0.00323918ZM12.7645 8.99157C12.22 8.97303 11.6515 9.18061 11.1721 9.57602C10.2062 10.3717 9.9345 11.6768 10.7287 12.6393C10.7303 12.6417 10.8262 12.761 10.9907 12.9618C12.8177 15.1563 15.2439 18.1008 15.2439 18.1008C18.3093 21.8195 16.9411 26.3935 12.7847 29.0037C9.12338 31.3028 2.40223 30.4797 0.0857897 26.7063C1.88137 32.0025 7.89945 35.4648 14.5585 33.8002C20.3347 32.3556 26.797 24.9497 19.7389 16.3878L14.8004 10.4225L14.236 9.71709C13.8369 9.24268 13.3091 9.01012 12.7645 8.99157Z'
      fill='#353535'
    />
  </svg>
);

const Memo = memo(VectorIcon6);
export { Memo as VectorIcon6 };
