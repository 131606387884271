import type { FC } from 'react';
import { memo } from 'react';

import resets from '../../_resets.module.css';
import Footer from '../Shared/Footer/Footer';
import Header from '../Shared/Header';
import { Component5 } from './Component5/Component5';
import { Component6 } from './Component6/Component6';
import classes from './MobileContactPage.module.css';
import { MobileSendButton_Property1Defa } from './MobileSendButton_Property1Defa/MobileSendButton_Property1Defa';
import { OkIcon } from './OkIcon';
import { SendEmailForm } from './SendEmailForm/SendEmailForm';

interface Props {
  className?: string;
}
/* @figmaId 251:391 */
export const MobileContactPage: FC<Props> = memo(function MobileContactPage(props = {}) {
  return (
    <div className={`${resets.clapyResets} ${classes.root}`}>
      <div className={classes.ok}>
        <OkIcon className={classes.icon2} />
      </div>
      <div className={classes.contactUs}>Contact Us.</div>

      <SendEmailForm className={classes.sendEmailForm}/>
      {/* <MobileSendButton_Property1Defa className={classes.mobileSendButton} />
      <Component5 />
      <Component5
        className={classes.component6}
        text={{
          name: <div className={classes.name}>Your E-Mail</div>,
        }}
      />
      <Component5
        className={classes.component7}
        text={{
          clickAndStartTyping: <div className={classes.clickAndStartTyping}>+90</div>,
          name: <div className={classes.name2}>Phone Number</div>,
        }}
      />
      <Component6 /> */}

      <Header/>
      <Footer/>
    </div>
  );
});
