import { memo, SVGProps } from 'react';

const MainpageStarIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg preserveAspectRatio='none' viewBox='0 0 276 276' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M138 0L166.319 60.1935L226.705 32.2859L209.707 96.6L273.903 114.037L219.542 152.378L257.512 207L191.223 201.428L185.199 267.678L138 220.8L90.8012 267.678L84.7772 201.428L18.4885 207L56.4579 152.378L2.09653 114.037L66.2931 96.6L49.2953 32.2859L109.681 60.1935L138 0Z'
      fill='#24FFB5'
    />
  </svg>
);

const Memo = memo(MainpageStarIcon);
export { Memo as MainpageStarIcon };
