import { memo, SVGProps } from 'react';

const OkIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg preserveAspectRatio='none' viewBox='0 0 79 57' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M3.23458 0.0312704C4.16791 0.210216 4.77863 1.1076 4.59866 2.03563C2.00868 15.3913 4.03822 24.0453 7.97429 29.3744C10.0743 32.2176 12.7881 34.2103 15.8137 35.4824C16.1187 29.6364 19.067 24.1958 23.0029 20.3811C26.4464 17.0436 30.816 14.7548 35.151 14.5073C39.6048 14.253 43.8445 16.1762 46.6748 20.8796C48.2803 23.5476 48.5504 26.2948 47.7088 28.8581C46.8868 31.3613 45.0585 33.5367 42.7361 35.288C38.102 38.7827 31.0835 40.9143 24.1112 40.6732C22.6422 40.6224 21.1648 40.4655 19.7037 40.1905C20.2483 42.3679 21.2932 44.5398 22.94 46.6235C28.4459 53.5899 37.4854 54.9517 47.5312 52.3669C56.2751 50.117 65.4364 44.9322 72.8137 38.4154L61.6846 38.4998C60.7341 38.507 59.9577 37.7467 59.9505 36.8016C59.9432 35.8565 60.7079 35.0845 61.6583 35.0773L77.1475 34.9598C78.098 34.9525 78.8744 35.7129 78.8817 36.658L78.9999 52.0592C79.0072 53.0043 78.2426 53.7763 77.2921 53.7835C76.3416 53.7907 75.5652 53.0304 75.5579 52.0853L75.4701 40.6445C67.6741 47.6298 57.88 53.2394 48.3936 55.6803C37.7022 58.4313 26.9455 57.2305 20.2336 48.7384C17.789 45.6453 16.4585 42.4071 15.9871 39.2116C11.8713 37.7947 8.07299 35.2901 5.19995 31.4003C0.496986 25.0329 -1.47734 15.2905 1.21878 1.38761C1.39875 0.459577 2.30125 -0.147675 3.23458 0.0312704ZM19.242 36.595C20.8664 36.9807 22.5424 37.1943 24.2309 37.2527C30.5132 37.4699 36.7297 35.522 40.6561 32.561C42.614 31.0845 43.8973 29.4382 44.4366 27.7959C44.9561 26.2136 44.8371 24.4916 43.721 22.6369C41.5495 19.0283 38.5248 17.7429 35.3483 17.9243C32.053 18.1125 28.4266 19.9039 25.4055 22.832C21.718 26.406 19.185 31.3828 19.242 36.595Z'
      fill='#24FFB5'
    />
  </svg>
);

const Memo = memo(OkIcon);
export { Memo as OkIcon };
