import { memo, SVGProps } from 'react';

const Uber_logo_black_rgbOptimizedIc = (props: SVGProps<SVGSVGElement>) => (
  <svg preserveAspectRatio='none' viewBox='0 0 177 108' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M50.1016 67.7477C55.0723 67.7477 58.9243 63.8945 58.9243 58.1863V35.9775H64.307V71.9635H58.9748V68.6246C56.5609 71.1456 53.2304 72.579 49.4877 72.579C41.8004 72.579 35.8962 66.972 35.8962 58.4983V35.986H41.279V58.1947C41.279 63.9956 45.0721 67.7477 50.1016 67.7477ZM68.3021 35.9775H73.483V49.0886C75.8379 46.6688 79.1264 45.1848 82.7598 45.1848C90.447 45.1848 96.5026 51.2977 96.5026 58.9114C96.5026 66.4661 90.4554 72.5874 82.7598 72.5874C79.118 72.5874 75.7874 71.095 73.4241 68.6836V71.9719H68.2936V35.9775H68.3021ZM82.3981 68.0006C87.3183 68.0006 91.3722 63.9366 91.3722 58.903C91.3722 53.8103 87.3183 49.8053 82.3981 49.8053C77.4275 49.8053 73.3736 53.8187 73.3736 58.903C73.3736 63.9451 77.377 68.0006 82.3981 68.0006ZM111.986 45.2354C119.522 45.2354 125.065 51.0448 125.065 58.8608V60.5556H103.786C104.501 64.8219 108.042 68.0091 112.449 68.0091C115.477 68.0091 118.042 66.7781 119.985 64.1559L123.728 66.9298C121.112 70.4289 117.218 72.5284 112.449 72.5284C104.602 72.5284 98.5548 66.6685 98.5548 58.8524C98.5548 51.4495 104.35 45.2354 111.986 45.2354ZM103.887 56.441H119.833C118.959 52.4275 115.729 49.7547 111.886 49.7547C108.042 49.7547 104.812 52.4275 103.887 56.441ZM138.959 50.3702C135.578 50.3702 133.114 52.9924 133.114 57.0565V71.9635H127.933V45.7497H133.063V48.9875C134.342 46.8796 136.444 45.5474 139.321 45.5474H141.112V50.3787H138.959V50.3702Z'
      fill='black'
    />
  </svg>
);

const Memo = memo(Uber_logo_black_rgbOptimizedIc);
export { Memo as Uber_logo_black_rgbOptimizedIc };
