import type { FC } from 'react';
import { memo } from 'react';

import resets from '../../_resets.module.css';
import Footer from '../Shared/Footer/Footer';
import Header from '../Shared/Header';
import { AiBrainIcon } from './AiBrainIcon';
import classes from './TabletAbout.module.css';

interface Props {
  className?: string;
}
/* @figmaId 368:342 */
export const TabletAbout: FC<Props> = memo(function TabletAbout(props = {}) {
  return (
    <div className={`${resets.clapyResets} ${classes.root}`}>
      <Header/>
      <div className={classes.frame23}>
        <div className={classes.ronoWasEstablishedInAnkaraIn20}>
          <p className={classes.labelWrapper}>
            <span className={classes.label}>
              {' '}
              Rono was established in Ankara in 2022. It continues to develop projects in the field of data analytics
              for various public and private sector institutions and provides training in data analytics. With its young
              team that develops solutions in{' '}
            </span>
            <span className={classes.label2}>
              Data Analytics, Artificial Intelligence Applications, Data Collection, and Mobile Software
            </span>
            <span className={classes.label3}>, it carries out R&amp;D activities within the scope of TEKMER.</span>
          </p>
        </div>
      </div>
      <div className={classes.frame24}>
        <div className={classes.aboutUs}>About Us.</div>
      </div>
      <div className={classes.aIBRAIN}>
        <AiBrainIcon className={classes.icon2} />
      </div>
      <Footer/>
    </div>
  );
});
