import { memo, SVGProps } from 'react';

const VectorIcon6 = (props: SVGProps<SVGSVGElement>) => (
  <svg preserveAspectRatio='none' viewBox='0 0 138 85' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M18.2361 85C16.24 85 14.5657 84.32 13.213 82.96C11.8604 81.6 11.1865 79.9189 11.1912 77.9166C11.1912 75.9097 11.8675 74.2262 13.2201 72.8662C14.5727 71.5062 16.2447 70.8286 18.2361 70.8333H56.9832L2.03273 15.5833C0.741162 14.2847 0.0648478 12.6626 0.0037917 10.7171C-0.0572644 8.77152 0.61905 7.08805 2.03273 5.66667C3.32431 4.36806 4.96812 3.71875 6.96419 3.71875C8.96025 3.71875 10.6041 4.36806 11.8956 5.66667L67.5506 61.4479L104.184 24.6146C103.715 23.5521 103.362 22.4612 103.128 21.3421C102.893 20.2229 102.775 19.0117 102.775 17.7083C102.775 12.75 104.478 8.55902 107.883 5.13541C111.288 1.7118 115.456 0 120.388 0C125.319 0 129.487 1.7118 132.892 5.13541C136.297 8.55902 138 12.75 138 17.7083C138 22.6667 136.297 26.8576 132.892 30.2812C129.487 33.7048 125.319 35.4167 120.388 35.4167C119.331 35.4167 118.302 35.3293 117.302 35.1546C116.302 34.9799 115.334 34.713 114.399 34.3542L78.118 70.8333H116.865C118.861 70.8333 120.536 71.5133 121.888 72.8733C123.241 74.2333 123.915 75.9144 123.91 77.9166C123.91 79.9236 123.234 81.6071 121.881 82.9671C120.529 84.3271 118.857 85.0047 116.865 85H18.2361Z'
      fill='#353535'
    />
  </svg>
);

const Memo = memo(VectorIcon6);
export { Memo as VectorIcon6 };
