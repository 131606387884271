import { memo, SVGProps } from 'react';

const VectorIcon4 = (props: SVGProps<SVGSVGElement>) => (
  <svg preserveAspectRatio='none' viewBox='0 0 150 80' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M137.493 2.68685e-06C135.458 0.000526749 133.453 0.497969 131.654 1.44911C129.855 2.40025 128.316 3.77633 127.169 5.45778C126.023 7.13923 125.304 9.07521 125.076 11.0975C124.847 13.1198 125.116 15.1673 125.859 17.062L99.5578 43.3644C96.6259 42.2102 93.3655 42.2102 90.4335 43.3644L71.6317 24.5618C72.3762 22.6666 72.6465 20.6183 72.419 18.5949C72.1915 16.5714 71.4732 14.6342 70.3266 12.9517C69.1799 11.2691 67.6396 9.89218 65.8395 8.94059C64.0395 7.989 62.0342 7.49158 59.9981 7.49158C57.962 7.49158 55.9568 7.989 54.1567 8.94059C52.3567 9.89218 50.8163 11.2691 49.6697 12.9517C48.523 14.6342 47.8047 16.5714 47.5772 18.5949C47.3498 20.6183 47.6201 22.6666 48.3646 24.5618L17.0636 55.864C14.3905 54.8153 11.4377 54.7191 8.70195 55.5915C5.96617 56.4639 3.61418 58.2517 2.04146 60.6543C0.468731 63.0569 -0.228809 65.9278 0.0661235 68.7842C0.361056 71.6406 1.63047 74.3084 3.66093 76.3389C5.69138 78.3695 8.35904 79.6389 11.2154 79.9339C14.0717 80.2288 16.9424 79.5313 19.3449 77.9585C21.7474 76.3857 23.5352 74.0336 24.4075 71.2977C25.2799 68.5618 25.1836 65.609 24.135 62.9357L55.4359 31.6335C58.3679 32.7876 61.6283 32.7876 64.5603 31.6335L83.3621 50.436C82.6176 52.3312 82.3473 54.3795 82.5748 56.403C82.8022 58.4264 83.5205 60.3636 84.6672 62.0462C85.8139 63.7287 87.3542 65.1056 89.1543 66.0572C90.9543 67.0088 92.9596 67.5062 94.9957 67.5062C97.0317 67.5062 99.037 67.0088 100.837 66.0572C102.637 65.1056 104.177 63.7287 105.324 62.0462C106.471 60.3636 107.189 58.4264 107.417 56.403C107.644 54.3795 107.374 52.3312 106.629 50.436L132.931 24.1337C134.642 24.8061 136.481 25.0926 138.316 24.9727C140.151 24.8528 141.936 24.3295 143.546 23.4402C145.155 22.5508 146.549 21.3172 147.627 19.8275C148.705 18.3377 149.441 16.6284 149.782 14.8215C150.124 13.0146 150.063 11.1546 149.603 9.3741C149.143 7.5936 148.296 5.9365 147.122 4.52095C145.949 3.1054 144.477 1.96628 142.813 1.18481C141.148 0.40335 139.332 -0.00120295 137.493 2.68685e-06Z'
      fill='#353535'
    />
  </svg>
);

const Memo = memo(VectorIcon4);
export { Memo as VectorIcon4 };
