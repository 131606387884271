import type { FC } from 'react';
import { memo } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import { DesktopAbout } from './components/Desktop/About/About';
import { Desktop1440ContactPage } from './components/Desktop/Desktop1440ContactPage/Desktop1440ContactPage';
import { Desktop1440Responsive } from './components/Desktop/Desktop1440Responsive/Desktop1440Responsive';
import { About } from './components/Mobile/About/About';
import { Mobile320px } from './components/Mobile/Mobile320px/Mobile320px';
import { MobileContactPage } from './components/Mobile/MobileContactPage/MobileContactPage';
import { TabletAbout } from './components/Tablet/TabletAbout/TabletAbout';
import { TabletContactPage } from './components/Tablet/TabletContact/TabletContactPage';
import { TabletMain } from './components/Tablet/TabletMain/TabletMain';
interface Props {
  className?: string;
}
export const App: FC<Props> = memo(function App(props = {}) {

  function isMobile() {
    return window.matchMedia("(min-width: 300px) and (max-width: 768px)").matches;
  }  

  function deviceType(page: string): JSX.Element | null{
    if(window.matchMedia("(min-width: 300px) and (max-width: 768px)").matches){
      switch(page){
        case("main"): return <Mobile320px />;
        case("about"): return <About />;
        case("contact"): return <MobileContactPage />;
      } 
    }
    else if(window.matchMedia("(min-width: 768px) and (max-width: 1024px)").matches){
      switch(page){
        case("main"): return <TabletMain />;
        case("about"): return <TabletAbout />;
        case("contact"): return <TabletContactPage />;
      }
    }
    else{
      switch(page){
        case("main"): return <Desktop1440Responsive />;
        case("about"): return <DesktopAbout />;
        case("contact"): return <Desktop1440ContactPage />;
      }
    }

    return null;

  }

  let mainPage = deviceType('main');
  let aboutPage = deviceType('about');
  let contactPage = deviceType('contact');

  return (
    <Router>
      <Routes>
      <Route path='/' element={mainPage} />
      <Route path='/about' element={aboutPage} />
      <Route path='/contact' element={contactPage} />
      </Routes>
    </Router>
  );
});
