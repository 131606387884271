import { memo, SVGProps } from 'react';

const VectorIcon4 = (props: SVGProps<SVGSVGElement>) => (
  <svg preserveAspectRatio='none' viewBox='0 0 71 44' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M9.67944 43.7192C8.66699 43.7293 7.81429 43.3929 7.12134 42.7099C6.42838 42.0269 6.07803 41.1776 6.07029 40.162C6.06014 39.1441 6.39467 38.2867 7.07388 37.5901C7.75309 36.8934 8.59773 36.5413 9.60782 36.5336L29.2612 36.3377L1.10983 8.59155C0.448148 7.93939 0.096907 7.12006 0.0561025 6.13354C0.015298 5.14702 0.349828 4.28971 1.05969 3.56161C1.70824 2.8964 2.53874 2.55874 3.55118 2.54865C4.56363 2.53856 5.40069 2.85959 6.06237 3.51175L34.5738 31.5238L52.9689 12.656C52.7253 12.1194 52.5411 11.5679 52.4164 11.0014C52.2916 10.435 52.2259 9.82118 52.2193 9.1601C52.1943 6.64513 53.0366 4.51077 54.7465 2.75703C56.4563 1.00329 58.5618 0.113953 61.0632 0.0890223C63.5645 0.0640918 65.6874 0.911283 67.4318 2.6306C69.1762 4.34991 70.061 6.46705 70.086 8.98202C70.1111 11.497 69.2687 13.6313 67.5589 15.3851C65.8491 17.1388 63.7436 18.0282 61.2422 18.0531C60.7062 18.0584 60.1841 18.0193 59.6758 17.9358C59.1675 17.8522 58.6754 17.7218 58.1995 17.5445L39.9812 36.2309L59.6346 36.035C60.647 36.0249 61.4997 36.3613 62.1927 37.0443C62.8857 37.7273 63.236 38.5766 63.2437 39.5922C63.2539 40.6101 62.9194 41.4675 62.2402 42.1641C61.5609 42.8608 60.7163 43.2129 59.7062 43.2206L9.67944 43.7192Z'
      fill='#353535'
    />
  </svg>
);

const Memo = memo(VectorIcon4);
export { Memo as VectorIcon4 };
