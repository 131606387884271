import { memo, SVGProps } from 'react';

const VectorIcon2 = (props: SVGProps<SVGSVGElement>) => (
  <svg preserveAspectRatio='none' viewBox='0 0 105 105' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M17.5 81.6667H87.5L65.625 52.5L48.125 75.8333L35 58.3333L17.5 81.6667ZM17.5 52.5C27.2222 52.5 35.4861 49.0972 42.2917 42.2917C49.0972 35.4861 52.5 27.2222 52.5 17.5H42.5833C42.5833 24.5 40.1528 30.4306 35.2917 35.2917C30.4306 40.1528 24.5 42.5833 17.5 42.5833V52.5ZM17.5 32.6667C21.6806 32.6667 25.2058 31.1831 28.0758 28.2158C30.9458 25.2486 32.3789 21.6767 32.375 17.5H17.5V32.6667ZM11.6667 105C8.45834 105 5.71084 103.857 3.42418 101.57C1.13751 99.2833 -0.00387898 96.5378 9.90379e-06 93.3333V11.6667C9.90379e-06 8.45834 1.14334 5.71084 3.43001 3.42418C5.71668 1.13751 8.46223 -0.00387898 11.6667 9.90379e-06H93.3333C96.5417 9.90379e-06 99.2892 1.14334 101.576 3.43001C103.862 5.71668 105.004 8.46223 105 11.6667V93.3333C105 96.5417 103.857 99.2892 101.57 101.576C99.2833 103.862 96.5378 105.004 93.3333 105H11.6667ZM11.6667 93.3333H93.3333V11.6667H11.6667V93.3333Z'
      fill='#353535'
    />
  </svg>
);

const Memo = memo(VectorIcon2);
export { Memo as VectorIcon2 };
