import { memo } from 'react';
import type { FC } from 'react';

import resets from '../../_resets.module.css';
import { Component1 } from './Component1/Component1';
import { ContactButton_Property1Default } from './ContactButton_Property1Default/ContactButton_Property1Default';
import classes from './Desktop1440Responsive.module.css';
import { Ellipse1Icon } from './Ellipse1Icon';
import { Frame18_Property1Default } from './Frame18_Property1Default/Frame18_Property1Default';
import { HazineBakanlG1OptimizedIcon } from './HazineBakanlG1OptimizedIcon';
import { Hopi1OptimizedIcon } from './Hopi1OptimizedIcon';
import { Logo } from './Logo/Logo';
import { MainpageDataIllustrationIcon } from './MainpageDataIllustrationIcon';
import { MainpageStarIcon } from './MainpageStarIcon';
import { MigrosOptimizedIcon } from './MigrosOptimizedIcon';
import { NgLogo1OptimizedIcon } from './NgLogo1OptimizedIcon';
import { SasLogoOptimizedIcon } from './SasLogoOptimizedIcon';
import { Star1Icon } from './Star1Icon';
import { Subjects_Property1Frame17 } from './Subjects_Property1Frame17/Subjects_Property1Frame17';
import { TicaretBakanlGLogo1OptimizedIc } from './TicaretBakanlGLogo1OptimizedIc';
import { Uber_logo_black_rgbOptimizedIc } from './Uber_logo_black_rgbOptimizedIc';
import { Vector1Icon } from './Vector1Icon';
import { VectorIcon2 } from './VectorIcon2';
import { VectorIcon3 } from './VectorIcon3';
import { VectorIcon4 } from './VectorIcon4';
import { VectorIcon5 } from './VectorIcon5';
import { VectorIcon6 } from './VectorIcon6';
import { VectorStrokeIcon } from './VectorStrokeIcon';
import { WhiteRectangleIcon } from './WhiteRectangleIcon';
import { Footer } from '../Shared/Footer/Footer';

interface Props {
  className?: string;
}
/* @figmaId 1:2 */
export const Desktop1440Responsive: FC<Props> = memo(function Desktop1440Responsive(props = {}) {
  return (
    <div className={`${resets.clapyResets} ${classes.root}`}>
      <div className={classes.whiteRectangle}>
        <WhiteRectangleIcon className={classes.icon} />
      </div>
      <div className={classes.mainpageStar}>
        <MainpageStarIcon className={classes.icon2} />
      </div>
      <div className={classes.mainpageDataIllustration}>
        <MainpageDataIllustrationIcon className={classes.icon3} />
      </div>
      <div className={classes.rectangle2}></div>
      <div className={classes.star1}>
        <Star1Icon className={classes.icon4} />
      </div>
      <div className={classes.ellipse1}>
        <Ellipse1Icon className={classes.icon5} />
      </div>
      <div className={classes.solutions}>Solutions</div>
      <div className={classes.trainings}>Trainings</div>
      <div className={classes.services}>Services</div>
      {/* <div className={classes.pageEnd}>
        <div className={classes.computerTech}>Computer Tech.</div>
        <div className={classes.ankaraTEKMERCevizlidereMahMevl}>
          <p className={classes.labelWrapper}>
            <span className={classes.label}>Ankara TEKMER</span>
            <span className={classes.label2}> </span>
          </p>
          <div className={classes.textBlock}>Cevizlidere Mah. Mevlana Bul. No:221</div>
          <div className={classes.textBlock2}>İç Kapı No: 105</div>
          <div className={classes.textBlock3}>
            <p className={classes.labelWrapper2}>
              <span className={classes.label3}>ÇANKAYA/ANKARA</span>
            </p>
          </div>
        </div>
        <Component3 />
        <Linkedin_Property1Default className={classes.linkedin} />
        <Twitter_Property1Default className={classes.twitter} />
        <Youtube_Property1Default className={classes.youtube} />
        <Facebook_Property1Default className={classes.facebook} />
        <div className={classes.vector}>
          <VectorIcon className={classes.icon6} />
        </div>
      </div> */}
      <Footer/>
      <div className={classes.satelliteImagerySolutions}>
        <div className={classes.satelliteImageryAnalysis}>Satellite Imagery Analysis.</div>
        <div className={classes.weUseSatelliteImageryToTrackVe}>
          {' '}
          We use satellite imagery to track vegetation, agriculture, water resources, buildings &amp; movable assets.
        </div>
        <div className={classes.vector2}>
          <VectorIcon2 className={classes.icon7} />
        </div>
      </div>
      <div className={classes.deepDiveAnalysisSolutions}>
        <div className={classes.deepDiveAnalysisFromSocialMedi}>
          <div className={classes.textBlock4}>Deep Dive Analysis</div>
          <div className={classes.textBlock5}>From Social Media.</div>
        </div>
        <div className={classes.analysisOfTrendsHiddenNetworks}>
          {' '}
          Analysis of trends, hidden networks, disinformation and fake news, as well as finding most effective or
          influential.
        </div>
        <div className={classes.vectorStroke}>
          <VectorStrokeIcon className={classes.icon8} />
        </div>
      </div>
      <div className={classes.digitalForensicsSolutions}>
        <div className={classes.digitalForensics}>
          <div className={classes.textBlock6}>Digital</div>
          <div className={classes.textBlock7}>Forensics.</div>
        </div>
        <div className={classes.identificationOfEditedImagesVi}>
          {' '}
          Identification of edited images, videos and sound files, handwritten signature authentication.
        </div>
        <div className={classes.vector3}>
          <VectorIcon3 className={classes.icon9} />
        </div>
      </div>
      <div className={classes.sasViyaTrainings}>
        <div className={classes.sASViyaTrainingsImplementation}>SAS Viya Trainings &amp; Implementations</div>
        <div className={classes.asAPartnerOfSASWeHelpSASCustom}>
          {' '}
          As a partner of SAS, we help SAS customers and integrators for their needs of SAS trainings and
          implementation.
        </div>
        <Component1 />
      </div>
      <div className={classes.analyticsProjectServices}>
        <div className={classes.analyticsProjectImplementation}>Analytics Project Implementations</div>
        <div className={classes.weHelpYourAnalyticProjectImple}>
          {' '}
          We help your analytic project implementations with open source technologies.
        </div>
        <div className={classes.vector4}>
          <VectorIcon4 className={classes.icon10} />
        </div>
      </div>
      <div className={classes.dataScienceTrainings}>
        <div className={classes.dataScienceAnalyticsTrainings}>
          <div className={classes.textBlock8}>Data Science &amp;</div>
          <div className={classes.textBlock9}>Analytics Trainings.</div>
        </div>
        <div className={classes.fundamentalsOfDataManagementDa}>
          {' '}
          Fundamentals of data management, data analytics, common tools and applications.
        </div>
        <div className={classes.vector5}>
          <VectorIcon5 className={classes.icon11} />
        </div>
      </div>
      <div className={classes.networkAndLinkSolutions}>
        <div className={classes.networkAndLinkAnalysis}>Network and Link Analysis.</div>
        <div className={classes.identifyNetworksAndRelationsBe}>
          {' '}
          Identify networks and relations between people, enterprises and on online assets.
        </div>
        <div className={classes.vector6}>
          <VectorIcon6 className={classes.icon12} />
        </div>
      </div>
      <div className={classes.decideThroughData}>
        <div className={classes.textBlock10}>Decide Through</div>
        <div className={classes.textBlock11}>Data.</div>
      </div>
      <div className={classes.rectangle12}></div>
      <div className={classes.rectangle13}></div>
      <div className={classes.rectangle17}></div>
      <div className={classes.rectangle15}></div>
      <div className={classes.rectangle16}></div>
      <div className={classes.vector1}>
        <Vector1Icon className={classes.icon13} />
      </div>
      <div className={classes.asRonoWeSupportOurCustomersToI}>
        As Rono, we support our customers to implement data analytics projects, we give analytics trainings and develop
        solutions to be used as-a-service.
      </div>
      <div className={classes.rectangle14}></div>
      <Subjects_Property1Frame17 className={classes.subjects} />
      <div className={classes.hOPI1Optimized}>
        <Hopi1OptimizedIcon className={classes.icon14} />
      </div>
      <div className={classes.NgLogo1Optimized}>
        <NgLogo1OptimizedIcon className={classes.icon15} />
      </div>
      <div className={classes.mIGROSOptimized}>
        <MigrosOptimizedIcon className={classes.icon16} />
      </div>
      <div className={classes.dR1}></div>
      <div className={classes.hazineBakanlG1Optimized}>
        <HazineBakanlG1OptimizedIcon className={classes.icon17} />
      </div>
      <div className={classes.ticaretBakanlGLogo1Optimized}>
        <TicaretBakanlGLogo1OptimizedIc className={classes.icon18} />
      </div>
      <div className={classes.sasLogoOptimized}>
        <SasLogoOptimizedIcon className={classes.icon19} />
      </div>
      <div className={classes.references}>References</div>
      <div className={classes.partnerships}>Partnerships</div>
      <div className={classes.whatsAppImage2023524At17571}></div>
      <div className={classes.uber_Logo_Black_RGBOptimized}>
        <Uber_logo_black_rgbOptimizedIc className={classes.icon20} />
      </div>
      <div className={classes.hEADER}>
        <ContactButton_Property1Default
          className={classes.contactButton}
          text={{
            contactUs: <div className={classes.contactUs}>Contact Us</div>,
          }}
        />
        <Logo />
        <Frame18_Property1Default className={classes.frame18} />
      </div>
    </div>
  );
});
