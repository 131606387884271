import { memo, SVGProps } from 'react';

const MainpageDataIllustrationIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg preserveAspectRatio='none' viewBox='0 0 142 145' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M118.334 53.8628L94.6682 62.4318L71.0022 53.8628L94.6682 45.296L118.334 53.8628ZM94.6682 8.56678L71.0022 17.1358L47.3363 8.56678L71.0022 0L94.6682 8.56678ZM71.0022 34.465L47.3363 43.034L23.6726 34.4672H23.6704V34.465L47.3363 25.8982L71.0022 34.465Z'
      fill='#DCDCDC'
    />
    <path d='M23.677 127.86V127.864L23.6726 127.862L23.677 127.86Z' fill='#1F1F1F' />
    <path
      d='M94.6682 76.9999L71 85.5667L47.3407 77.0021L47.3341 76.9999L47.3407 76.9977L71 68.4309H71.0022L94.6682 76.9999ZM47.3341 63.8981L23.6682 72.467L0.00222135 63.8981L23.6682 55.3313H23.6704L47.3341 63.8981Z'
      fill='#DCDCDC'
    />
    <path d='M23.6682 127.862V127.864L23.6659 127.862H23.6682Z' fill='#1F1F1F' />
    <path
      d='M23.6682 72.467V127.864L23.6659 127.862H23.6637L0.00222135 119.295V63.8981L23.6682 72.467ZM71 85.5667V145L47.3363 136.431H47.3341L47.3319 136.429V119.297L47.3363 119.295L47.3319 119.293L47.3341 76.9999L47.3407 77.0021L71 85.5667Z'
      fill='#24FFB5'
    />
    <path
      d='M142 119.293L71 145V85.5667L94.6682 76.9999V62.4318L118.334 53.8628V110.726H118.336L142 119.293Z'
      fill='#1F1F1F'
    />
    <path
      d='M47.3363 43.034L47.3341 63.8981L23.6704 55.3313H23.6682L0.00666744 46.7645V25.8982L23.6704 34.465V34.4672H23.6726L47.3363 43.034ZM71.0022 17.1358V34.465L47.3363 25.8982V8.56678L71.0022 17.1358ZM94.6682 62.4318V76.9999L71.0022 68.4309V53.8628L94.6682 62.4318Z'
      fill='#24FFB5'
    />
    <path
      d='M118.334 0V36.7292L94.6682 45.296L71.0022 53.8628V68.4309H71L47.3407 76.9977L47.3341 76.9999L47.3319 119.293L47.3363 119.295L47.3319 119.297V136.429L23.6659 144.998L0 136.429L23.6637 127.862H23.6659L23.6682 127.864V127.862H23.6726L23.677 127.864V127.866H23.6793V127.864H23.677V72.4692L47.3341 63.9024V63.8981L47.3363 43.034L71.0022 34.465V17.1358L94.6682 8.56678V8.56898L118.334 0Z'
      fill='#1F1F1F'
    />
    <path
      d='M23.677 127.864H23.6793V127.866H23.677V127.864ZM47.3452 119.297L47.3407 119.3L47.3319 119.302V119.297L47.3363 119.295L47.3319 119.293L47.3341 76.9999L47.3407 76.9977V119.295L47.3452 119.297Z'
      fill='#1F1F1F'
    />
  </svg>
);

const Memo = memo(MainpageDataIllustrationIcon);
export { Memo as MainpageDataIllustrationIcon };
